import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.page.html',
  styleUrls: ['./stats.page.scss']
})
export class StatsPage implements OnInit {

  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    this._title.setTitle("Haizzy | Analytics");
    this._meta.updateTag({
      name: "description",
      content: "Suivez nos données analytics"
    });
  }

}
