// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top__banner {
  width: 100%;
}

.main-wrapper_grid {
  min-height: 30vh;
}

.select-wrapper {
  text-align: center;
}

.main-wrapper_filter {
  text-align: center;
  margin: 1.6rem auto;
}
.main-wrapper_filter.desktop li {
  margin: 0.5rem;
  font-weight: 700;
  font-size: 0.75em;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: var(--default-radius);
  background-color: var(--second-background-color);
}
.main-wrapper_filter.desktop li:not(.selected) {
  color: var(--primary-color);
  cursor: pointer;
}
.main-wrapper_filter.desktop li.selected {
  color: var(--text-button-color);
  background-color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/blog/blog.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;AACJ;AAGQ;EACI,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,oCAAA;EACA,gDAAA;AADZ;AAGY;EACI,2BAAA;EACA,eAAA;AADhB;AAIY;EACI,+BAAA;EACA,wCAAA;AAFhB","sourcesContent":[".top__banner {\n    width: 100%;\n}\n\n.main-wrapper_grid {\n    min-height: 30vh;\n}\n\n.select-wrapper {\n    text-align: center;\n}\n\n.main-wrapper_filter {\n    text-align: center;\n    margin: 1.6rem auto;\n\n    &.desktop {\n\n        li {\n            margin: 0.5rem;\n            font-weight: 700;\n            font-size: 0.75em;\n            padding: .5em 1em;\n            display: inline-block;\n            border-radius: var(--default-radius);\n            background-color: var(--second-background-color);\n\n            &:not(.selected) {\n                color: var(--primary-color);\n                cursor: pointer;\n            }\n\n            &.selected {\n                color: var(--text-button-color);\n                background-color: var(--secondary-color);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
