// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.story {
  background: var(--second-background-color);
}
@media (min-width: 1024px) {
  .story {
    padding: 0 4rem;
  }
}
@media (max-width: 1023px) and (min-width: 640px) {
  .story {
    max-width: 100%;
  }
  .story section {
    max-width: 50rem;
  }
}

.value ol {
  list-style-type: decimal;
}
.value ol,
.value ul {
  line-height: 1rem;
  margin-left: 0.75rem;
  margin-bottom: 2.5rem;
  list-style-position: outside;
}
.value ol li,
.value ul li {
  line-height: 3rem;
  font-size: 1.6rem;
  padding-left: 0.75rem;
  color: var(--text-color);
}

@media (max-width: 1023px) {
  .customer .gallery li:nth-of-type(1) img {
    object-position: top;
  }
  .customer .gallery li:nth-of-type(2) img {
    object-position: 50% 27%;
  }
  .customer .gallery li:nth-of-type(3) img {
    object-position: 50% 20%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/about/about.page.scss"],"names":[],"mappings":"AAAA;EAaI,0CAAA;AAXJ;AADI;EADJ;IAEQ,eAAA;EAIN;AACF;AAFI;EALJ;IAMQ,eAAA;EAKN;EAHM;IACI,gBAAA;EAKV;AACF;;AAEI;EACI,wBAAA;AACR;AAEI;;EAEI,iBAAA;EACA,oBAAA;EACA,qBAAA;EACA,4BAAA;AAAR;AAEQ;;EACI,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,wBAAA;AACZ;;AAIA;EAIgB;IACI,oBAAA;EAJlB;EASc;IACI,wBAAA;EAPlB;EAYc;IACI,wBAAA;EAVlB;AACF","sourcesContent":[".story {\n    @media (min-width: 1024px) {\n        padding: 0 4rem;\n    }\n\n    @media (max-width: 1023px) and (min-width: 640px) {\n        max-width: 100%;\n\n        section {\n            max-width: 50rem;\n        }\n    }\n\n    background: var(--second-background-color);\n}\n\n.value {\n    ol {\n        list-style-type: decimal;\n    }\n\n    ol,\n    ul {\n        line-height: 1rem;\n        margin-left: 0.75rem;\n        margin-bottom: 2.5rem;\n        list-style-position: outside;\n\n        li {\n            line-height: 3rem;\n            font-size: 1.6rem;\n            padding-left: 0.75rem;\n            color: var(--text-color);\n        }\n    }\n}\n\n@media (max-width: 1023px) {\n    .customer {\n        .gallery {\n            li:nth-of-type(1) {\n                img {\n                    object-position: top;\n                }\n            }\n\n            li:nth-of-type(2) {\n                img {\n                    object-position: 50% 27%;\n                }\n            }\n\n            li:nth-of-type(3) {\n                img {\n                    object-position: 50% 20%;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
