import { Meta, Title } from '@angular/platform-browser';
import { Articles } from 'src/app/content/articles.content';
import { iArticle } from 'src/app/interfaces/article.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';


@Component({
  selector: 'app-article',
  templateUrl: './article.page.html',
  styleUrls: ['./article.page.scss']
})

export class ArticlePage implements OnInit {

  public related_articles: iArticle[] = []
  public show_navigation: boolean = true;
  public is_registered: boolean = false;
  public article!: iArticle;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _metaService: MetaService,
    private readonly _router: Router,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    let path: string = this._activatedRoute.snapshot.params["slug"];
    if (this._activatedRoute.snapshot.url[1].path.includes("é")) {
      path = this._activatedRoute.snapshot.url[1].path.replace("é", "e");
      this._router.navigateByUrl(`blog/${path}`);
    }
    this.fetchArticle(path);
    this.handleQueryParams(this._activatedRoute.snapshot.queryParams);
  }

  fetchArticle(slug: string): void {

    console.log(slug);
    const article = Articles.find((article) => article.slug == slug);
    if (article != null) {
      this.article = article;
      this.handleArticle(this.article);
      this.handleRelatedArticles(this.article);
    }
  }

  handleArticle(article: iArticle): void {
    if (article == undefined) this._router.navigateByUrl('404'); else {
      this.article = article;
      this.article.content = article.content;
      const title: string = article.meta.title.replace("<color>", "");
      this._title.setTitle(title);
      this._meta.updateTag({
        name: "description",
        content: article.meta.description
      });
      this._meta.updateTag({
        property: "og:url",
        content: `https://haizzy.fr/blog/${article.slug}`
      });
      this._meta.updateTag({
        property: "og:title",
        content: title
      });
      this._meta.updateTag({
        property: "og:description",
        content: article.meta.description
      });
      this._meta.updateTag({
        property: "og:image",
        content: `https://haizzy.fr${article.cover.img}`
      });
    };
  }

  handleRelatedArticles(article: iArticle): void {
    article.related.forEach((related) => {
      const index: number = Articles.findIndex(content => content.slug === related);
      this.related_articles.push(Articles[index]);
    });
  }

  handleQueryParams(params: any): void {
    this.show_navigation = params.embed == undefined || params.embed != undefined && params.embed == "false";
  }


}
