import { Injectable } from '@angular/core';
import { iDay } from '../interfaces/day.interface';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  private currentYear: number;
  private currentMonthIndex: number;

  constructor() {
    let date = new Date();
    this.currentYear = date.getFullYear();
    this.currentMonthIndex = date.getMonth();
  }

  public getCurrentMonth(): iDay[] {
    return this.getMonth(this.currentMonthIndex, this.currentYear);
  }

  public getMonth(monthIndex: number, year: number): iDay[] {
    let days = [];

    let firstday = this.createDay(1, monthIndex, year);

    //create empty days
    for (let i = 1; i < firstday.weekDayNumber; i++) {
      days.push({
        weekDayNumber: i,
        monthIndex: monthIndex,
        year: year,
      } as iDay);
    }
    days.push(firstday);
    //

    let countDaysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    for (let i = 2; i < countDaysInMonth + 1; i++) {
      days.push(this.createDay(i, monthIndex, year));
    }

    return days;
  }

  public getMonthName(monthIndex: number): string {
    switch (monthIndex) {
      case 0:
        return "Janvier";
      case 1:
        return "Février";
      case 2:
        return "Mars";
      case 3:
        return "Avril";
      case 4:
        return "Mai";
      case 5:
        return "Juin";
      case 6:
        return "Juillet";
      case 7:
        return "Août";
      case 8:
        return "Septembre";
      case 9:
        return "Octobre";
      case 10:
        return "Novembre";
      case 11:
        return "Décembre";
      default:
        return "";
    }
  }

  public getWeekDayName(weekDay: number): string {
    switch (weekDay) {
      case 0:
        return "di"; // Sunday
      case 1:
        return "lu"; // Monday
      case 2:
        return "ma"; // Tuesday
      case 3:
        return "me"; // Wednesday
      case 4:
        return "je"; // Thursday
      case 5:
        return "ve"; // Friday
      case 6:
        return "sa"; // Saturday

      default:
        return "";
    }
  }

  private createDay(dayNumber: number, monthIndex: number, year: number) {
    const month = this.getMonthName(monthIndex);
    const weekDayNumber = new Date(year, monthIndex, dayNumber).getDay();
    const weekDayName = this.getWeekDayName(weekDayNumber);

    const day: iDay = {
      monthIndex: monthIndex,
      month: month,
      number: dayNumber,
      year: year,
      weekDayNumber: weekDayNumber,
      weekDayName: weekDayName
    };

    return day;
  }
}
