import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() no_background: boolean = false;
  @Input() full_width: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    if (this.visible) {
      const body = document.body;
      body.classList.add("no_scroll");
    }
  }

  close(): void {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  ngOnDestroy() {
    const body = document.body;
    body.classList.remove("no_scroll");
  }

}
