import { createClient, PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  private supabase: SupabaseClient;
  private API_URL: string = environment.BASE;
  private supabase_url: string = environment.SUPABASE_URL as string;
  private supabase_key: string = environment.SUPABASE_KEY as string;

  constructor(private readonly _httpClient: HttpClient) {
    this.supabase = createClient(this.supabase_url, this.supabase_key);
  }

  fetchPost(id: string): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('pictures').select(`
      *,
      collection: collection_id( id, name ),
      profile: profile_id( id, username, avatar_url )
    `).eq('id', id).single();
  }

  fetchComments(id: string): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('comments')
      .select(`
        *,
        profile: profile_id( id, username, avatar_url )
      `)
      .eq('picture_id', id)
      .order('created_at', { ascending: true });
  }

  fetchLikes(id: string): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('likes')
      .select('id')
      .eq('picture_id', id);
  }

  fetchTips(slug: string): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('tips')
      .select("*")
      .eq('slug', slug)
      .order('created_at', { ascending: true });
  }

  fetchCollection(): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('collections')
      .select()
      .order('created_at', { ascending: false })
  }

  postPictureFile(formData: FormData): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/upload`, formData);
  }

  fetchInstagramPost(user_id: number, token: string): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/instagram`, {
      key: 'mediaWithToken',
      value: {
        instagram_user_id: user_id,
        access_token: token,
      }
    });
  }

  uploadToBucket(body: any): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/bucket`, {
      key: 'uploadToBucket',
      value: {
        url: body.media_url,
        filename: body.filename,
        bucket_name: `posts/${body.supa_id}`
      }
    });
  }

  sharePicture(body: any): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('pictures')
      .insert({
        thumbnails: body.thumbnails,
        ratio: body.ratio,
        is_video: false,
        video_url: "",
        profile_id: body.supa_id,
        collection_id: body.collection_id,
        description: body.description,
        number_of_images: 1,
      })
  }
}
