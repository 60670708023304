import { createClient, PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  private supabase: SupabaseClient;
  private API_URL: string = environment.BASE;
  private supabase_url: string = environment.SUPABASE_URL as string;
  private supabase_key: string = environment.SUPABASE_KEY as string;

  constructor(private _httpClient: HttpClient) {
    this.supabase = createClient(this.supabase_url, this.supabase_key);
  }

  onPresubscribe(body: Object): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/presubscribe`, body);
  }

  fetchHairdressersById(id: string | undefined): PromiseLike<PostgrestSingleResponse<any>> {
    const query: string = id == undefined ? "" : id;
    return this.supabase
      .from('profiles')
      .select(`*`)
      .eq('id', query);
  }

  fetchHairdressersByNickname(username: string | undefined): PromiseLike<PostgrestSingleResponse<any>> {
    const query: string = username == undefined ? "" : username;
    return this.supabase
      .from('profiles')
      .select(`*`)
      .ilike('username', query);
  }

  fetchHairdresserPictures(id: string | undefined): PromiseLike<PostgrestSingleResponse<any>> {
    const query: string = id == undefined ? "" : id;
    return this.supabase
      .from('pictures')
      .select(`*`)
      .order('created_at', { ascending: false })
      .eq('profile_id', query);
  }

  fetchHairdresserLikes(id: string | undefined): PromiseLike<PostgrestSingleResponse<any>> {
    const query: string = id == undefined ? "" : id;
    return this.supabase
      .from('likes')
      .select(`pictures(
        *,
        collections: collection_id(
          name
          ),
        profiles: profile_id( 
          id, username, avatar_url, certified, is_a_partner, zip_code
          )
      )`)
      .order('created_at', { ascending: false })
      .eq('profile_id', query);
  }

  fetchSubscribes(id: string): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('subscribes')
      .select(`profiles:profile_subscribed_id(avatar_url, username)`)
      .match({ 'profile_id': id, 'is_subscribe': true });
  }

  getInstagramToken(body: any): Observable<any> {
    return this._httpClient.post(`${this.API_URL}/instagram`, {
      key: 'getInstagramToken',
      value: {
        code: body.code,
        supa_user_id: body.supa_user_id,
      }
    })
  }

  onUpdatePassword(access_token: string, new_password: string) {
    return this.supabase.auth.api.updateUser(access_token, { password: new_password });
  }
}
