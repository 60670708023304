// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout .wrap h2 {
  color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/app/pages/faq/faq.page.scss"],"names":[],"mappings":"AAEQ;EACI,6BAAA;AADZ","sourcesContent":[".layout {\n    .wrap {\n        h2 {\n            color: var(--secondary-color);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
