import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OauthService } from '../services/oauth.service';
import { Session } from '@supabase/supabase-js';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class OauthGuard {
  private session: Session | null = this._oauthService.session;
  constructor(private readonly _oauthService: OauthService, private readonly _router: Router,) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this._oauthService.authChanges((_, session) => this.session = session);
    if (this.session == null) {
      this._router.navigateByUrl("/oauth");
    }
    return this.session != null;
  }
}

/*

export class AuthService {
  private supabase: SupabaseClient
  private currentUser: BehaviorSubject<User | boolean> = new BehaviorSubject(null)

  constructor(private router: Router) {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)

    this.supabase.auth.onAuthStateChange((event, sess) => {
      if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        console.log('SET USER')

        this.currentUser.next(sess.user)
      } else {
        this.currentUser.next(false)
      }
    })

    // Trigger initial session load
    this.loadUser()
  }

  async loadUser() {
    if (this.currentUser.value) {
      // User is already set, no need to do anything else
      return
    }
    const user = await this.supabase.auth.getUser()

    if (user.data.user) {
      this.currentUser.next(user.data.user)
    } else {
      this.currentUser.next(false)
    }
  }

  signUp(credentials: { email; password }) {
    return this.supabase.auth.signUp(credentials)
  }

  signIn(credentials: { email; password }) {
    return this.supabase.auth.signInWithPassword(credentials)
  }

  sendPwReset(email) {
    return this.supabase.auth.resetPasswordForEmail(email)
  }

  async signOut() {
    await this.supabase.auth.signOut()
    this.router.navigateByUrl('/', { replaceUrl: true })
  }

  getCurrentUser(): Observable<User | boolean> {
    return this.currentUser.asObservable()
  }

  getCurrentUserId(): string {
    if (this.currentUser.value) {
      return (this.currentUser.value as User).id
    } else {
      return null
    }
  }

  signInWithEmail(email: string) {
    return this.supabase.auth.signInWithOtp({ email })
  }
}*/