// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store_badge a {
  display: inline-block;
  vertical-align: middle;
  height: 4rem;
  margin-right: 1.5rem;
}
.store_badge a img {
  width: auto;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/store/store.component.scss"],"names":[],"mappings":"AACI;EACI,qBAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;AAAR;AAEQ;EACI,WAAA;EACA,YAAA;AAAZ","sourcesContent":[".store_badge {\n    a {\n        display: inline-block;\n        vertical-align: middle;\n        height: 4rem;\n        margin-right: 1.5rem;\n\n        img {\n            width: auto;\n            height: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
