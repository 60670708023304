import { AuthChangeEvent, createClient, GoTrueApi, GoTrueClient, Session, SupabaseClient } from '@supabase/supabase-js';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { iCredentials, iProfile } from '../interfaces/profile.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OauthService {
  private supabase: SupabaseClient;
  private API_URL: string = environment.BASE;
  private supabase_url: string = environment.SUPABASE_URL as string;
  private supabase_key: string = environment.SUPABASE_KEY as string;

  constructor(private _httpClient: HttpClient) {
    this.supabase = createClient(this.supabase_url, this.supabase_key);
  }

  get user() {
    return this.supabase.auth.user();
  }

  get session() {
    return this.supabase.auth.session();
  }

  get profile() {
    return this.supabase
      .from('profiles')
      .select(`username, website, avatar_url`)
      .eq('id', this.user?.id)
      .single();
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  async signup(credentials: iCredentials): Promise<any> {
    return await this.supabase.auth.signUp(credentials);
  }

  async signIn(credentials: iCredentials): Promise<any> {
    return await this.supabase.auth.signIn(credentials);
  }

  async checkIfUsernameIsAvailable(username: string): Promise<any> {
    return await this.supabase
      .from('profiles')
      .select('username')
      .ilike('username', username);
  }

  signOut() {
    return this.supabase.auth.signOut();
  }

  updateProfile(profile: iProfile) {
    const update = {
      ...profile,
      id: this.user?.id,
      updated_at: new Date()
    }

    return this.supabase.from('profiles').upsert(update, {
      returning: 'minimal', // Don't return the value after inserting
    });
  }

  setWelcomeMail(email: string): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/user`, {
      key: "welcome",
      value: {
        email: email
      }
    });
  }

  getInstagramToken(body: Object): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/instagram`, {
      key: "getInstagramToken",
      value: body
    });
  }

  getMediaWithToken(body: Object): Observable<Object> {
    return this._httpClient.post(`${this.API_URL}/instagram`, {
      key: "mediaWithToken",
      value: body
    });
  }

  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage
      .from('avatars')
      .upload(filePath, file);
  }
}