import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';
import { OauthService } from 'src/app/services/oauth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss']
})
export class AccountPage implements OnInit {
  session = this._oauthService.session;

  constructor(
    private readonly _title: Title,
    private readonly _meta: Meta,
    private readonly _oauthService: OauthService,
    private readonly _metaService: MetaService) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    this._title.setTitle("Haizzy | Profile");
    this._meta.updateTag({
      name: "description",
      content: "Votre profile Haizzy"
    });
    this._oauthService.authChanges((_, session) => this.session = session);
  }

}
