import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as faq from 'src/app/content/faq.content';
import { iFaq } from 'src/app/interfaces/faq.interface';
import { iPartner } from 'src/app/interfaces/partner.interface';
import { MainService } from 'src/app/services/main.service';
import { MetaService } from 'src/app/services/meta.service';
import { environment } from '../../../environments/environment';
import { iPicture } from 'src/app/interfaces/picture.interface';
import { iPost, iPostThumbnails } from 'src/app/interfaces/post.interface';
import { Session } from '@supabase/supabase-js';
import { iSubscribe } from 'src/app/interfaces/subscribe.interface';

@Component({
  selector: 'app-partner-profil',
  templateUrl: './partner-profil.page.html',
  styleUrls: ['./partner-profil.page.scss']
})
export class PartnerProfilPage implements OnInit {

  private url: string = "";

  public thumbsSwiper: any;
  public partner!: iPartner;
  public posts: iPost[] = [];
  public likes: iPost[] = [];
  public selected: string = "";
  public preview: string[] = [];
  public loading: boolean = false;
  public modal_content: string = "";
  public pictures: iPicture[] = [];
  public show_posts: boolean = true;
  public selected_post_id: string = "";
  public subscribes: iSubscribe[] = [];
  public modal_is_open: boolean = false;
  public is_registered: boolean = false;
  public API_URL: String = environment.BASE;
  public about_haircut: iFaq[] = faq.Haircut;
  public show_instagram_grid: boolean = false;
  public linkTo: string = "/faq#about_customer";
  public fetchInstagramPostAfterRedirection: boolean = false;

  @Input() public session!: Session | null;

  constructor(
    private readonly _meta: Meta,
    private readonly _title: Title,
    private readonly _router: Router,
    private readonly _sanitizer: DomSanitizer,
    private readonly _metaService: MetaService,
    private readonly _mainService: MainService,
    private readonly _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.session == null) {
      this.getHairdresserDataByUsername();
    } else if (this.session != null) {
      this.refreshAccountData();
    }
    this.url = window.location.origin + window.location.pathname;

  }

  public refreshAccountData(): void {
    if (this.session != null) this.getHairdresserDataById(this.session.user!.id);
  }

  private getHairdresserDataById(id: string): void {
    this._mainService.fetchHairdressersById(id).then((snapshot) => {
      if (snapshot.data != null && snapshot.data.length > 0) {
        this.handleProfileResponse(snapshot.data[0]);
      } else {
        this._router.navigateByUrl('404');
      }
    });
  }

  private getHairdresserDataByUsername(): void {
    const username = this._activatedRoute.snapshot.params["username"];
    this._mainService.fetchHairdressersByNickname(username).then((snapshot) => {
      if (snapshot.data != null && snapshot.data.length > 0) {
        this.handleProfileResponse(snapshot.data[0]);
      } else {
        this._router.navigateByUrl('404');
      }
    });
  }

  private handleProfileResponse(data: any): void {
    let self_presentation = this.verifyLastCharacter(data["self_presentation"]);
    this.partner = {
      self_presentation: self_presentation,
      description: data["self_presentation"],
      avatar_url: this.setDefaultAvatar(data["avatar_url"]),
      is_partner: data["is_a_partner"],
      instagram: data["instagram"] != null ? data["instagram"] : {
        access_token: "",
        user_id: 0
      },
      username: data["username"],
      street: data["street"],
      email: data["email"],
      zip: data["zip_code"],
      city: data["city"],
      id: data["id"],
    };
    this.checkInstagramAccessToken();
    this.setMetadata();

    this._mainService.fetchHairdresserPictures(data["id"]).then((snapshot) => {
      if (snapshot.data != null) {
        this.handlePictureResponse(snapshot.data);
      }
    });

    if (this.session != null) {
      this._mainService.fetchSubscribes(data["id"]).then((snapshot) => {
        if (snapshot.data != null) {
          this.handleSubscription(snapshot.data);
        }
      })
      this._mainService.fetchHairdresserLikes(data["id"]).then((snapshot) => {
        if (snapshot.data != null) {
          this.handlePictureLikesResponse(snapshot.data);
        }
      });
    }
  }

  private handleSubscription(data: any) {
    this.subscribes = [];
    data.forEach((subscribe: any) => {
      this.subscribes.push({
        avatar_url: this.setDefaultAvatar(subscribe["profiles"]["avatar_url"]),
        username: subscribe["profiles"]["username"],
      })
    })
  }
  private handlePictureLikesResponse(data: any) {
    data.forEach((post: any) => {
      let thumbnails: iPostThumbnails[] = []
      post["pictures"]["thumbnails"].forEach((thumbnail: iPostThumbnails) => {
        thumbnails.push(thumbnail);
      });
      this.likes.push({
        created_at: new Date(Date.parse(post["pictures"]["created_at"])),
        description: post["pictures"]["description"] ?? "",
        id: post["pictures"]["id"],
        thumbnails: thumbnails
      });
    });
  }

  private handlePictureResponse(data: any): void {
    this.posts = [];
    data.forEach((post: any) => {
      let thumbnails: iPostThumbnails[] = []
      post["thumbnails"].forEach((thumbnail: iPostThumbnails) => {
        thumbnails.push(thumbnail);
      });
      this.posts.push({
        created_at: new Date(Date.parse(post["created_at"])),
        description: post["description"] ?? "",
        id: post["id"],
        thumbnails: thumbnails
      });
    });
  }

  private checkInstagramAccessToken(): void {
    const query_params = this._activatedRoute.snapshot.queryParams;
    if (query_params["code"] != undefined && this.session != null) {
      this.loading = true;
      this._mainService.getInstagramToken({
        code: query_params["code"],
        supa_user_id: this.session.user!.id,
      }).subscribe(
        (response: any) => {
          console.log(response);
          // this.handleInstagramAccessToken(response["data"],
        },
        (err: any) => {
          console.log("error");
          console.error(err.message);
          this.loading = false;
        },
      )
    };
  }

  private handleInstagramAccessToken(instagram: { access_token: string; user_id: number; }): void {
    this.partner.instagram = {
      access_token: instagram.access_token,
      user_id: instagram.user_id,
    };
    this.show_instagram_grid = true;
    this.modal_content = "new_post";
    this.loading = false;
    this.modal_is_open = true;
    this.fetchInstagramPostAfterRedirection = true;
  }

  private setDefaultAvatar(url: string): string {
    const default_url: string = "https://lhcqvkvsjtjsjbjyaffc.supabase.in/storage/v1/object/public/avatars/default_avatar.png";
    return url != null ? url : default_url;
  }

  public verifyLastCharacter(presentation: string): string {
    const emoji = /\p{Emoji}/u;
    const characters = /[ `!\/?]/;
    const str: string[] = presentation.split(" ");

    str.forEach((char, index) => {
      if (emoji.test(char) || characters.test(char)) {
        str[index] = "&nbsp;" + char;
      } else {
        str[index] = " " + char;
      }
    });
    presentation = str.join("");
    return "<p>" + presentation + "</p>";
  }

  public setMetadata(): void {
    this._title.setTitle(`Haizzy | Profil de ${this.partner.username}`);
    this._meta.updateTag({
      name: "description",
      content: this.partner.self_presentation,
    })
    this._meta.updateTag({
      property: "og:title",
      content: `Haizzy | Profil de ${this.partner.username}`,
    });
    this._meta.updateTag({
      property: "og:description",
      content: this.partner.self_presentation,
    })
    this._meta.updateTag({
      property: "og:url",
      content: `https://haizzy.fr/${this.partner.username}`,
    })

    this._meta.updateTag({
      property: "og:image",
      content: this.partner.avatar_url,
    })
  }

  public togglePostsTabs(is_show: boolean): void {
    this.show_posts = is_show;
  }

  public transform(html: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustUrl(html);
  }

  public showDetail(id: string): void {
    this.selected_post_id = id;
    window.history.pushState({}, '', `partner/${this.partner.username}/${id}`);
    this.modal_is_open = true;
  }

  public openModalFromHero(type: string): void {
    this.modal_content = type;
    this.modal_is_open = true;
  }

  public close(): void {
    window.history.pushState({}, '', this.url);
    this.show_instagram_grid = false;
    this.modal_is_open = false;
    this.selected_post_id = "";
    this.modal_content = "";
  }

}
