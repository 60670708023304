import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss']
})
export class AboutPage implements OnInit {

  public is_registered: boolean = false;
  constructor(
    private readonly _title: Title,
    private readonly _meta: Meta,
    private readonly _metaService: MetaService) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {

    this._title.setTitle("Haizzy | À propos de nous");
    this._meta.updateTag({
      name: "description",
      content: "Nous nous sommes donné pour mission de vous aider à trouver une coiffeuse Afro à domicile partout en France, Strasbourg, Lyon, Paris, Nantes, ect"
    });
  }

}
