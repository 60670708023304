import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { PostComment, iPostResponse, iPostThumbnails } from 'src/app/interfaces/post.interface';
import { MetaService } from 'src/app/services/meta.service';
import { PostService } from 'src/app/services/post.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as faq from 'src/app/content/faq.content';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iFaq } from 'src/app/interfaces/faq.interface';

// import Swiper core and required modules
import SwiperCore, { EffectCards } from "swiper";

SwiperCore.use([EffectCards]);


@Component({
  selector: 'app-post',
  templateUrl: './post.page.html',
  styleUrls: ['./post.page.scss']
})
export class PostPage implements OnInit {

  @Input() id: string = "";
  @Input() with_footer: boolean = true;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  public total_likes: number = 0;
  public waiting: boolean = true;
  public total_comments: number = 0;
  public comments: PostComment[] = [];
  public is_registered: boolean = false;
  public about_haircut: iFaq[] = faq.Haircut;
  public linkTo: string = "/faq#about_customer";
  public post_response: iPostResponse = <iPostResponse>{};

  constructor(
    private readonly _meta: Meta,
    private readonly _title: Title,
    private readonly _router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly _postService: PostService,
    private readonly _metaService: MetaService,
    private readonly _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const id: string = this.id != "" ? this.id : this._activatedRoute.snapshot.params["post"];
    this.fetchPost(id);

  }

  private fetchPost(id: string): void {
    this.fetchPostLikes(id);
    this.fetchPostComments(id);
    this._postService.fetchPost(id).then((snapshot) => {
      if (snapshot.data != null) {
        this.handleResponse(snapshot.data);
      } else {
        this._router.navigateByUrl('404');
      }
    });
  }

  private handleResponse(data: any): void {
    let thumbnails: iPostThumbnails[] = []
    data["thumbnails"].forEach((thumbnail: iPostThumbnails) => {
      thumbnails.push(thumbnail);
    });
    this.post_response = {
      collection: {
        id: data["collection"]["id"],
        name: data["collection"]["name"]
      },
      post: {
        created_at: new Date(Date.parse(data["created_at"])),
        description: data["description"] ?? "",
        thumbnails: thumbnails,
        id: data["id"]
      },
      profile: {
        avatar_url: data["profile"]["avatar_url"],
        username: data["profile"]["username"],
        id: data["profile"]["id"]
      }
    }
    this.setMetadata();
  }

  private setMetadata(): void {
    this._title.setTitle(`${this.post_response.collection.name} par ${this.post_response.profile.username}`);
    this._meta.updateTag({
      name: "description",
      content: this.post_response.post.description
    });
    this._meta.updateTag({
      property: "og:url",
      content: `https://haizzy.fr/post/${this.post_response.post.id}`
    });
    this.waiting = false;
  }

  private fetchPostLikes(id: string): void {
    this._postService.fetchLikes(id).then((snapshot) => {
      if (snapshot.data != null) {
        this.total_likes = snapshot.data.length;
      }
    });
  }

  private fetchPostComments(id: string): void {
    this._postService.fetchComments(id).then((snapshot) => {
      if (snapshot.data != null) {
        this.total_comments = snapshot.data.length;
        snapshot.data.forEach((data: any) => {
          this.comments.push(data);
        });
      }
    })
  }

  public transform(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustUrl(html);
  }

  public close(): void {
    this.visibleChange.emit(true);
  }
}
