import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { iPartner } from 'src/app/interfaces/partner.interface';

import { OauthService } from 'src/app/services/oauth.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  public loading = false;
  public uploading = false;
  public setting_content: string = "";

  @Input() account!: iPartner;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private readonly _oauthService: OauthService,
    private readonly _router: Router,
  ) { }

  ngOnInit(): void {
  }

  public uploadAvatar(event: any): void {
    try {
      this.uploading = true;
      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.');
      }
      // await this._oauthService.uploadAvatar(filePath, file);
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result != null) return this.account.avatar_url = reader.result!.toString();
        else return null;
      };

      reader.readAsDataURL(file);

    } catch (error) {
      // alert(error.message);
    } finally {
      this.uploading = false;
    }
  }

  async updateProfile(target: NgForm): Promise<void> {
    console.log(target.value);
  }

  public onSelectSetting(setting: string): void {
    this.setting_content = setting;
  }
  public signOut(): void {
    this._oauthService.signOut();
    this._router.navigateByUrl('/')
  }

  public close(): void {
    this.visibleChange.emit(true);
  }

}
