// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main .brand,
main .nav__container__logo,
main .not-found__content p {
  margin-bottom: 1rem;
}
main a.brand span.brand {
  color: var(--primary-color);
}
main * {
  text-align: center;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/not-found/not-found.page.scss"],"names":[],"mappings":"AAEI;;;EAGI,mBAAA;AADR;AAII;EACI,2BAAA;AAFR;AAKI;EACI,kBAAA;EACA,YAAA;AAHR","sourcesContent":["main {\n\n    .brand,\n    .nav__container__logo,\n    .not-found__content p {\n        margin-bottom: 1rem;\n    }\n\n    a.brand span.brand {\n        color: var(--primary-color);\n    }\n\n    * {\n        text-align: center;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
