import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.page.html',
  styleUrls: ['./privacy.page.scss']
})
export class PrivacyPage implements OnInit {
  public is_registered: boolean = false;
  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    this._title.setTitle("Haizzy | Politique de confidentialité");
    this._meta.updateTag({
      name: "description",
      content: "Chez Haizzy, nous sommes soucieux du respect de votre vie privée et prenons à cœur la protection de vos données personnelles."
    });
  }

}
