import { Component, OnInit } from '@angular/core';
import { iDay } from 'src/app/interfaces/day.interface';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  public year: number = 0;
  public monthDays: iDay[] = [];
  public monthName: string = "";
  public currentDay: string = "";
  public currentYear: string = "";
  public monthNumber: number = 0;
  public currentMonth: string = "";
  public weekDaysName: string[] = [];
  private currentDateTime: number = 0;
  public enablePrevious: boolean = false;

  constructor(private calenderService: CalendarService) { }

  ngOnInit(): void {
    this.onSetCurrentDate();
    this.weekDaysName.push("lu");
    this.weekDaysName.push("ma");
    this.weekDaysName.push("me");
    this.weekDaysName.push("je");
    this.weekDaysName.push("ve");
    this.weekDaysName.push("sa");
    this.weekDaysName.push("di");
    this.setMonthDays(this.calenderService.getCurrentMonth());
  }

  public onNextMonth(): void {
    this.monthNumber++;

    if (this.monthNumber == 12) {
      this.monthNumber = 0;
      this.year++;
    }
    this.setMonthDays(this.calenderService.getMonth(this.monthNumber, this.year));
  }

  public onPreviousMonth(): void {
    this.monthNumber--;

    if (this.monthNumber < 1) {
      this.monthNumber = 11;
      this.year--;
    }

    this.setMonthDays(this.calenderService.getMonth(this.monthNumber, this.year));
  }

  public onVerifyVailability(day: iDay): boolean {
    const month: string = day.monthIndex + 1 < 10 ? `0${day.monthIndex + 1}` : `${day.monthIndex + 1}`;
    const number: string = day.number < 10 ? `0${day.number}` : `${day.number}`;
    return Date.parse(`${day.year}-${month}-${number}T00:00:00`) < this.currentDateTime;
  }

  private setMonthName(): void {
    this.monthName = this.calenderService.getMonthName(this.monthNumber);
    const month: string = (this.monthNumber + 1) < 10 ? `0${this.monthNumber + 1}` : `${this.monthNumber + 1}`;
    this.enablePrevious = this.currentDateTime < Date.parse(`${this.year}-${month}-01T00:00:00`);
  }

  private setMonthDays(days: iDay[]): void {
    this.monthDays = days;
    this.monthNumber = this.monthDays[0].monthIndex;
    this.year = this.monthDays[0].year;
    this.setMonthName();
  }

  private onSetCurrentDate(): void {
    const date = new Date();
    this.currentYear = date.getFullYear().toString();
    this.currentDay = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    this.currentMonth = date.getMonth() < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const currentStringDateTime: string = `${this.currentYear}-${this.currentMonth}-${this.currentDay}T00:00:00`;
    this.currentDateTime = Date.parse(currentStringDateTime);
  }


}
