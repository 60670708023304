import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: number): string {
    const price_str: string = value.toString();
    const price_arr: string[] = price_str.split(".");

    if (price_arr.length > 1 && price_arr[1].startsWith("0")) {
      return price_str.replace(".", ",");
    } else if (parseInt(price_arr[1]) < 10) {
      const cents: string = `${price_arr[1]}0`;
      return `${price_arr[0]},${cents}€`;
    } else if (parseInt(price_arr[1]) > 10) {
      return `${price_arr[0]},${price_arr[1]}€`;
    } else {
      return `${price_arr[0]},00€`;
    }

  }

}
