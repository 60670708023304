import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { iNavItem } from 'src/app/interfaces/nav.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public path: string = '/';
  private body: HTMLElement | null = null;
  public isOpen: boolean = false;
  public navLink: iNavItem[] = [
    {
      link: '/about',
      selected: false,
      title: 'À propos',
      label: 'Accéder à notre page à-propos'
    },
    {
      link: '/haircuts',
      selected: false,
      title: 'Nos coupes',
      label: 'Accéder à nos coupes Afro'
    },
    {
      link: '/blog',
      selected: false,
      title: 'Le blog',
      label: 'Accéder à tous nos articles'
    },
    {
      link: '/shop',
      selected: false,
      title: 'La boutique',
      label: 'Accéder à la boutique'
    }
  ]
  public show_banner: boolean = false;
  constructor(private _router: Router) { }

  ngOnInit(): void {
    this.path = this._router.url;
    this.show_banner = this.path != "/concours";
    this.navLink.map(item => {
      if (item.link === this.path) item.selected = true;
    });
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen
    if (this.body != null) {
      if (this.isOpen) {
        this.body = document.body;
        this.body.classList.add('no_scroll');
      } else {
        this.body.classList.remove("no_scroll");
      }
    }
  }

}
