// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main .brand,
main .nav__container__logo,
main .not-found__content p {
  margin-bottom: 1rem;
}
main * {
  text-align: center;
  margin: auto;
}
main .form_container input {
  text-align: left;
  padding-left: 4rem;
}
main .form_container .toggle_input_type {
  top: 0;
  bottom: 0;
  padding: 0;
  margin: auto;
  width: 3.2rem;
  height: 3.2rem;
  position: absolute;
  background-size: 2.4rem;
  background: url("/assets/icons/icn_no_view.svg") no-repeat center;
}
main .form_container .toggle_input_type.view {
  background: url("/assets/icons/icn_view.svg") no-repeat center;
}
main .form_container p {
  text-align: left;
  font-size: 1.15rem;
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/forgot-password/forgot-password.page.scss"],"names":[],"mappings":"AAEI;;;EAGI,mBAAA;AADR;AAKI;EACI,kBAAA;EACA,YAAA;AAHR;AAOQ;EACI,gBAAA;EACA,kBAAA;AALZ;AAQQ;EACI,MAAA;EACA,SAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,uBAAA;EACA,iEAAA;AANZ;AAQY;EACI,8DAAA;AANhB;AAWQ;EACI,gBAAA;EACA,kBAAA;EACA,mBAAA;AATZ","sourcesContent":["main {\n\n    .brand,\n    .nav__container__logo,\n    .not-found__content p {\n        margin-bottom: 1rem;\n    }\n\n\n    * {\n        text-align: center;\n        margin: auto;\n    }\n\n    .form_container {\n        input {\n            text-align: left;\n            padding-left: 4.0rem;\n        }\n\n        .toggle_input_type {\n            top: 0;\n            bottom: 0;\n            padding: 0;\n            margin: auto;\n            width: 3.2rem;\n            height: 3.2rem;\n            position: absolute;\n            background-size: 2.4rem;\n            background: url('/assets/icons/icn_no_view.svg') no-repeat center;\n\n            &.view {\n                background: url('/assets/icons/icn_view.svg') no-repeat center;\n            }\n\n        }\n\n        p {\n            text-align: left;\n            font-size: 1.15rem;\n            margin-bottom: 2rem;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
