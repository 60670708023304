import { MainService } from 'src/app/services/main.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss']
})
export class ForgotPasswordPage implements OnInit {

  public done: boolean = false;
  private access_token: string = "";
  public input_type: string = "password";
  constructor(
    private readonly _router: Router,
    private readonly _mainService: MainService,
  ) { }

  ngOnInit(): void {
  }

  verifyAccessToken(): void {
    const storage = localStorage.getItem("supabase.auth.token") ?? "";
    if (storage != "") {
      const data = JSON.parse(storage);
      this.checkIfExpired(data["currentSession"]["expiresAt"], data["currentSession"]["access_token"]);
    } else {
      this._router.navigateByUrl('/404');
    }
  }

  checkIfExpired(expires_at: number, access_token: string): void {
    const isExpired: boolean = new Date(expires_at) > new Date();
    if (isExpired) {
      this._router.navigateByUrl('/404');
    } else {
      this.access_token = access_token;
    }
  }

  updatePassword(target: NgForm): void {
    const new_password: string = target.value['password'];
    this.verifyAccessToken();
    if (new_password.length > 5) {
      this._mainService.onUpdatePassword(this.access_token, new_password).then((snapshot) => {
        if (snapshot.error != null) {
          console.error(snapshot.error.message);
        }
        if (snapshot.data != null) {
          localStorage.removeItem("supabase.auth.token");
          this.done = true;
        }
      });
    }
  }

  toggleInputType(): void {
    if (this.input_type == "password") {
      this.input_type = "text";
    } else {
      this.input_type = "password";
    }
  }
}
