import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import localeFr from '@angular/common/locales/fr';

import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ViewportScroller, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
registerLocaleData(localeFr);

// COMPONENTS
import { AppComponent } from './app.component';
import { FaqComponent } from './components/faq/faq.component';
import { ModalComponent } from './components/modal/modal.component';
import { StoreComponent } from './components/store/store.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ConfettiComponent } from './components/confetti/confetti.component';
import { PlaceholdersComponent } from './components/placeholders/placeholders.component';
import { UploadPhotoComponent } from './components/upload-photo/upload-photo.component';
import { QualityIconsComponent } from './components/quality-icons/quality-icons.component';
import { WaitlistFormComponent } from './components/waitlist-form/waitlist-form.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';

// PAGES
import { CguPage } from './pages/cgu/cgu.page';
import { FaqPage } from './pages/faq/faq.page';
import { BlogPage } from './pages/blog/blog.page';
import { HomePage } from './pages/home/home.page';
import { PostPage } from './pages/post/post.page';
import { ShopPage } from './pages/shop/shop.page';
import { AboutPage } from './pages/about/about.page';
import { StatsPage } from './pages/stats/stats.page';
import { OauthPage } from './pages/oauth/oauth.page';
import { ArticlePage } from './pages/article/article.page';
import { PrivacyPage } from './pages/privacy/privacy.page';
import { PartnerPage } from './pages/partner/partner.page';
import { ProductPage } from './pages/product/product.page';
import { AccountPage } from './pages/account/account.page';
import { HaircutsPage } from './pages/haircuts/haircuts.page';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { PartnerProfilPage } from './pages/partner-profil/partner-profil.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';

// PIPE
import { PricePipe } from './pipes/price.pipe';
import { ComponentPipe } from './pipes/component.pipe';
import { ConcoursPage } from './pages/concours/concours.page';



@NgModule({
    declarations: [
        CguPage,
        FaqPage,
        HomePage,
        BlogPage,
        PostPage,
        AboutPage,
        ShopPage,
        PrivacyPage,
        PartnerPage,
        ArticlePage,
        ProductPage,
        HaircutsPage,
        NotFoundPage,
        PartnerProfilPage,
        ForgotPasswordPage,
        ComponentPipe,
        AppComponent,
        FaqComponent,
        ModalComponent,
        StoreComponent,
        HeaderComponent,
        FooterComponent,
        ConfettiComponent,
        CalendarComponent,
        WaitlistFormComponent,
        QualityIconsComponent,
        OauthPage,
        AccountPage,
        PlaceholdersComponent,
        AccountSettingsComponent,
        DragAndDropDirective,
        UploadPhotoComponent,
        StatsPage,
        PricePipe,
        ConcoursPage,
    ],
    imports: [
        FormsModule,
        SwiperModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ],
    providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }],
    bootstrap: [AppComponent]
})

export class AppModule { }