import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(@Inject(DOCUMENT) private dom: Document) { }

  createCanonicalURL() {
    let link: HTMLLinkElement | null = !!this.dom.querySelector("link[rel='canonical']") ? this.dom.querySelector("link[rel='canonical']") : this.dom.createElement('link');
    if (link != null) {
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      const url = this.dom.URL.includes("https") ? this.dom.URL : this.dom.URL.replace("http", "https");
      link.setAttribute('href', url);
    }
  }
}
