import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {

  // @HostBinding('class.fileover') public file_over: boolean = false;
  @Output() file_dropped: EventEmitter<any> = new EventEmitter();

  constructor() { }

  @HostListener('dragover', ['$event']) public onDragOver(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDrageLeave(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: any): void {
    evt.preventDefault();
    evt.stopPropagation();

    if (evt.dataTransfer.files.length > 0) {
      this.file_dropped.emit({
        target: {
          files: evt.dataTransfer.files
        }
      });
    };
  }

}
