import { iSimulation } from 'src/app/interfaces/simulation.interface';
export const CitySizeSimulation: iSimulation[] = [{
    selected: false,
    label: "Petite",
    value: 0
}, {
    selected: false,
    label: "Moyenne",
    value: 2
}, {
    selected: false,
    label: "Grande",
    value: 5
}];

export const WorkDaysSimulation: iSimulation[] = [{
    selected: false,
    label: "Moins de 5 jours",
    value: 2.5
}, {
    selected: false,
    label: "5 jours",
    value: 5
}, {
    selected: false,
    label: "Plus de 5 jours",
    value: 6
}];

export const HaircutLevelSimulation: iSimulation[] = [{
    selected: false,
    label: "Sur cheveux naturels",

    value: 30
}, {
    selected: false,
    label: "Avec des mèches",
    value: 60
}, {
    selected: false,
    label: "Toutes les coupes",
    value: 70
}];
