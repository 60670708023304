import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { iCountry } from 'src/app/interfaces/country.interface';
import { OauthService } from 'src/app/services/oauth.service';


@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.page.html',
  styleUrls: ['./oauth.page.scss']
})
export class OauthPage implements OnInit {
  public unavailable_username: boolean = false;
  public unavailable_email: boolean = false;
  public selected_country: string = "FR";
  public loading: boolean = false;
  public error: boolean = false;
  public auth: boolean = true;
  public countries: iCountry[] = [
    {
      name: "France",
      value: "france",
      code: "FR",
    },
    {
      name: "Belgique",
      value: "belgium",
      code: "BE",
    },
    {
      name: "Luxembourg",
      value: "luxembourg",
      code: "LU",
    },
    {
      name: "Suisse",
      value: "switzerland",
      code: "CH",
    },

  ];
  public signup_error: any = {
    username: false,
    password: false,
    zip_code: false,
    email: false,
  }

  constructor(
    private readonly _router: Router,
    private readonly _oauthService: OauthService,
  ) { }

  ngOnInit(): void { }

  private validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  private zipCodeFormatter(zip: number, country: string): string {
    const zip_str = zip.toString();
    return zip_str.length < 5 && country == "FR" ? "0" + zip_str : zip_str;
  }

  private resetInputError(): void {
    this.signup_error = {
      username: false,
      password: false,
      zip_code: false,
      email: false,
    }
  }

  private formValidator(target: NgForm): void {
    this.signup_error.username = target.value["username"].length < 3
      || target.value["username"].length > 24;

    this.signup_error.zip_code = isNaN(target.value["zip_code"])
      || target.value["zip_code"] < 999
      || target.value["zip_code"] > 9999
      && this.selected_country != "FR";

    this.signup_error.email = !this.validateEmail(target.value["email"]);
    this.signup_error.password = target.value["password"].length < 6;
  }

  public authSwitcher(): void {
    this.error = false;
    this.auth = !this.auth;
  }

  public async validateUsername(username: string): Promise<void> {
    let { data, error } = await this._oauthService.checkIfUsernameIsAvailable(username);
    if (error != null) {
      this.error = true;
    } else if (data != null) {
      this.unavailable_username = data.length != 0;
    }
  }

  public async handleSignup(target: NgForm): Promise<any> {
    this.formValidator(target);
    await this.validateUsername(target.value["username"]);

    if (!this.signup_error.username && !this.signup_error.zip_code
      && !this.signup_error.email && !this.signup_error.password
      && !this.unavailable_username) {

      this.resetInputError();
      const { user, error } = await this._oauthService.signup({
        email: target.value["email"],
        password: target.value["password"]
      });

      if (user != null) {
        const zip_code = this.zipCodeFormatter(target.value["zip_code"], this.selected_country);
        const avatar_url = "https://lhcqvkvsjtjsjbjyaffc.supabase.co/storage/v1/object/public/avatars/default_avatar.png"
        await this._oauthService.updateProfile({
          username: target.value["username"],
          country_code: this.selected_country,
          avatar_url: avatar_url,
          zip_code: zip_code,
        });
        this._router.navigateByUrl('/account');
        this._oauthService.setWelcomeMail(target.value["email"]).subscribe();
      }
      if (error != null && error.status == 400) { this.unavailable_email = true; }
    } else {
      console.error("Can't sign up");
    }
  }

  public async handleLogin(target: NgForm): Promise<void> {
    try {
      this.loading = true;
      this.error = false;
      const { _, __, error } = await this._oauthService.signIn({
        email: target.value["email"],
        password: target.value["password"],
      });
      if (error != null) throw new Error(error);
    } catch (error: any) {
      this.error = true;
      if (error.status != undefined && error.status == 400) {

      };
    } finally {
      this.loading = false;
      this._router.navigateByUrl("/account");
    }
  }

  public selectFilter(value: string | null): void {
    if (value != null) this.selected_country = value;
  }


}
