// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main .brand,
main .nav__container__logo,
main .not-found__content p {
  margin-bottom: 1rem;
}
main a.brand span.brand {
  color: var(--primary-color);
}
main form {
  display: inline-block;
  max-width: 40rem;
  margin: auto;
}
main form .form_container h2 {
  margin-bottom: 0;
}
main form .form_container button {
  width: 100%;
}
main form .form_container .link {
  cursor: pointer;
  margin-top: 0.8rem;
  color: var(--contrast-color);
}
main form .form_container .link:hover {
  text-decoration: underline;
}
main .app-download {
  margin-bottom: 2rem;
}
main .app-download p {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/oauth/oauth.page.scss"],"names":[],"mappings":"AAEI;;;EAGI,mBAAA;AADR;AAII;EACI,2BAAA;AAFR;AAMI;EACI,qBAAA;EACA,gBAAA;EACA,YAAA;AAJR;AAOY;EACI,gBAAA;AALhB;AAQY;EACI,WAAA;AANhB;AASY;EACI,eAAA;EACA,kBAAA;EACA,4BAAA;AAPhB;AASgB;EACI,0BAAA;AAPpB;AAaI;EAKI,mBAAA;AAfR;AAWQ;EACI,kBAAA;AATZ","sourcesContent":["main {\n\n    .brand,\n    .nav__container__logo,\n    .not-found__content p {\n        margin-bottom: 1rem;\n    }\n\n    a.brand span.brand {\n        color: var(--primary-color);\n    }\n\n\n    form {\n        display: inline-block;\n        max-width: 40rem;\n        margin: auto;\n\n        .form_container {\n            h2 {\n                margin-bottom: 0;\n            }\n\n            button {\n                width: 100%;\n            }\n\n            .link {\n                cursor: pointer;\n                margin-top: 0.8rem;\n                color: var(--contrast-color);\n\n                &:hover {\n                    text-decoration: underline;\n                }\n            }\n        }\n    }\n\n    .app-download {\n        p {\n            text-align: center;\n        }\n\n        margin-bottom: 2rem;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
