import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-waitlist-form',
  templateUrl: './waitlist-form.component.html',
  styleUrls: ['./waitlist-form.component.scss']
})
export class WaitlistFormComponent implements OnInit {

  @Input() isDark: boolean = false;
  @Input() alignCenter: boolean = false;
  @Input() registered: boolean = false;
  @Output() registeredChange: EventEmitter<boolean> = new EventEmitter();

  public time: string = 'à l\'instant';
  public modal_is_open: boolean = false;
  public error: boolean = false;
  public load: boolean = false;
  public email: string = '';


  private interval: any;

  constructor(private _mainService: MainService) { }

  ngOnInit() {
    if (this.registered) {
      this.showMessage();
    }
  }


  public submitEmail(target: NgForm): void {
    this.modal_is_open = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(target.value['email']);
    if (this.modal_is_open) {
      this.email = target.value['email'];
      this.error = false;
    } else {
      this.error = true;
    }
  }

  public close(): void {
    this.modal_is_open = false;
    this.load = false;
  }

  public selectType(target: NgForm): void {
    this.load = true;
    setTimeout(() => {
      window.scrollTo(0, 0);
      this._mainService.onPresubscribe({
        email: this.email,
        type: target.value.type
      }).subscribe(
        (_: any) => this.responseSubscribe(),
        (err: any) => {
          console.error(err.message);
          this.responseSubscribe();
        }
      );
    }, 450);
  }

  public showMessage(): void {
    let minute: number = 0;
    this.interval = setInterval(() => {
      minute++;
      let plurials: string = minute > 1 ? 'minutes' : 'minute';
      this.time = `il y a ${minute} ${plurials}`;
    }, 60000);
  }

  public responseSubscribe(): void {
    this.modal_is_open = this.load = false;
    this.registeredChange.emit(true);
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }


}
