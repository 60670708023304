// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-profil-avatar {
  width: 15rem;
  margin: auto;
  height: 15rem;
  overflow: hidden;
  border-radius: 50%;
}
.account-profil-avatar input {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
}

form {
  width: 100%;
  margin-top: 1.6rem;
}
form .form_container {
  width: 90%;
  margin: auto;
}
form .form_container button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/account-settings/account-settings.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACI,MAAA;EACA,OAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;AACR;;AAGA;EACI,WAAA;EACA,kBAAA;AAAJ;AAEI;EACI,UAAA;EACA,YAAA;AAAR;AAEQ;EACI,WAAA;AAAZ","sourcesContent":[".account-profil-avatar {\n    width: 15rem;\n    margin: auto;\n    height: 15rem;\n    overflow: hidden;\n    border-radius: 50%;\n\n    input {\n        top: 0;\n        left: 0;\n        opacity: 0;\n        width: 100%;\n        height: 100%;\n        cursor: pointer;\n        position: absolute;\n    }\n}\n\nform {\n    width: 100%;\n    margin-top: 1.6rem;\n\n    .form_container {\n        width: 90%;\n        margin: auto;\n\n        button {\n            width: 100%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
