import { createClient, PostgrestSingleResponse, SupabaseClient } from '@supabase/supabase-js';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  private supabase: SupabaseClient;
  private supabase_url: string = environment.SUPABASE_URL as string;
  private supabase_key: string = environment.SUPABASE_KEY as string;
  constructor() {
    this.supabase = createClient(this.supabase_url, this.supabase_key);
  }


  fetchShopItems(): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('shop_items')
      .select("*")
      .eq('visible', true)
      .order('title', { ascending: true });
  }

  fetchShopItem(id: string): PromiseLike<PostgrestSingleResponse<any>> {
    return this.supabase
      .from('shop_items')
      .select("*")
      .eq('id', id).single();
  }
}
