// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.top__banner {
  width: 100%;
}
.top__banner .bloc_container {
  align-items: flex-start;
}

.main-wrapper_haircut .haircut {
  display: flex;
  margin-bottom: 6em;
  flex-direction: column;
  justify-content: space-between;
}
.main-wrapper_haircut .haircut:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 1400px) {
  .main-wrapper_haircut .haircut {
    flex-direction: row;
  }
  .main-wrapper_haircut .haircut .img__container {
    min-width: 50rem;
    padding-right: 4rem;
  }
}
.main-wrapper_haircut .haircut .bloc_container {
  padding: 4rem 0;
}
.main-wrapper_haircut .haircut .bloc_container h2 {
  width: 100%;
  font-weight: 700;
  margin-bottom: 2.5rem;
  letter-spacing: -0.025em;
}
@media (max-width: 64em) {
  .main-wrapper_haircut .haircut .bloc_container h2 {
    width: 66.666667%;
  }
}
.main-wrapper_haircut .haircut .bloc_container h2 span::after {
  content: "€";
  font-size: 70%;
}

.questions-wrapper {
  margin-top: 6em;
}
.questions-wrapper h2 {
  color: var(--secondary-color);
}

@media (max-width: 63.9375rem) {
  .faq__wrapper {
    padding: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/haircuts/haircuts.page.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,WAAA;AAEJ;AADI;EACI,uBAAA;AAGR;;AACI;EACI,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,8BAAA;AAER;AADQ;EACI,gBAAA;AAGZ;AADQ;EARJ;IASQ,mBAAA;EAIV;EAHU;IACI,gBAAA;IACA,mBAAA;EAKd;AACF;AAFQ;EACI,eAAA;AAIZ;AAHY;EACI,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;AAKhB;AAJgB;EALJ;IAMQ,iBAAA;EAOlB;AACF;AANgB;EACI,YAAA;EACA,cAAA;AAQpB;;AADA;EACI,eAAA;AAIJ;AAHI;EACI,6BAAA;AAKR;;AAAI;EADJ;IAEQ,UAAA;EAIN;AACF","sourcesContent":[".top__banner {\n    width: 100%;\n    .bloc_container {\n        align-items: flex-start;\n    }\n}\n.main-wrapper_haircut {\n    .haircut {\n        display: flex;\n        margin-bottom: 6em;\n        flex-direction: column;\n        justify-content: space-between;\n        &:last-of-type {\n            margin-bottom: 0;\n        }\n        @media (min-width: 1400px) {\n            flex-direction: row;\n            .img__container {\n                min-width: 50rem;\n                padding-right: 4rem;\n            }\n\n        }\n        .bloc_container {\n            padding: 4rem 0;\n            h2 {\n                width: 100%;\n                font-weight: 700;\n                margin-bottom: 2.5rem;\n                letter-spacing: -0.025em;\n                @media (max-width: 64em) {\n                    width: 66.666667%;\n                }\n                span::after {\n                    content: \"€\";\n                    font-size: 70%;\n                }\n            }\n        }\n    }\n}\n\n.questions-wrapper {\n    margin-top: 6em;\n    h2 {\n        color: var(--secondary-color);\n    }\n}\n\n.faq__wrapper {\n    @media (max-width: 63.9375rem){\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
