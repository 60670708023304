import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'component'
})
export class ComponentPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(html: string, ...args: unknown[]): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

}
