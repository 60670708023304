// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder_container {
  width: 90%;
  margin: auto;
  max-width: 27rem;
  text-align: center;
  flex-direction: column;
}
.placeholder_container .img__container {
  padding: 0;
}
.placeholder_container p {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/placeholders/placeholders.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA;AACJ;AACI;EACI,UAAA;AACR;AAEI;EACI,kBAAA;AAAR","sourcesContent":[".placeholder_container {\n    width: 90%;\n    margin: auto;\n    max-width: 27rem;\n    text-align: center;\n    flex-direction: column;\n\n    .img__container {\n        padding: 0;\n    }\n\n    p {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
