import { Component, Input, OnInit } from '@angular/core';
import { iFaq } from 'src/app/interfaces/faq.interface';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @Input() public show_mail_address: boolean = false;
  @Input() public onFaqPage: boolean = false;
  @Input() public faqLink: string = "/faq";
  @Input() public faq: iFaq[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  toogle(index: number): void {
    this.faq[index]['is_open'] = !this.faq[index]['is_open'];
  }

}
