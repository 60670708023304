// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
  margin: auto;
  max-width: 50rem;
}

span.darkness {
  color: #2A2F45;
}

.message__container {
  top: auto;
  left: auto;
  bottom: auto;
  position: relative;
}
.message__container span {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/waitlist-form/waitlist-form.component.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,gBAAA;AAAJ;;AAEA;EACI,cAAA;AACJ;;AACA;EACI,SAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AADI;EACI,aAAA;AAGR","sourcesContent":["\nform {\n    margin: auto;\n    max-width: 50rem;\n}\nspan.darkness {\n    color: #2A2F45;\n}\n.message__container {\n    top: auto;\n    left: auto;\n    bottom: auto;\n    position: relative;\n    span {\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
