// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top__banner {
  width: 100%;
}
.top__banner .baseline {
  max-width: 60rem;
}

.faq-title section {
  width: 100%;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/concours/concours.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACI;EACI,gBAAA;AACR;;AAII;EACI,WAAA;EACA,kBAAA;AADR","sourcesContent":[".top__banner {\n    width: 100%;\n\n    .baseline {\n        max-width: 60rem;\n    }\n}\n\n.faq-title {\n    section {\n        width: 100%;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
