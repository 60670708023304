// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  margin-top: -2rem;
}

main > div {
  min-height: 50vh;
}
main .load {
  margin-bottom: 50rem;
}
main .brand {
  margin: auto;
}
main a.brand span.brand {
  color: var(--primary-color);
}
main .product__content .h3 {
  font-size: 2.25rem;
  font-weight: bolder;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/product/product.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAGI;EACI,gBAAA;AAAR;AAGI;EACI,oBAAA;AADR;AAII;EACI,YAAA;AAFR;AAKI;EACI,2BAAA;AAHR;AAOQ;EACI,kBAAA;EACA,mBAAA;EACA,SAAA;AALZ","sourcesContent":[".layout {\n    margin-top: -2rem;\n}\n\nmain {\n    >div {\n        min-height: 50vh;\n    }\n\n    .load {\n        margin-bottom: 50rem;\n    }\n\n    .brand {\n        margin: auto;\n    }\n\n    a.brand span.brand {\n        color: var(--primary-color);\n    }\n\n    .product__content {\n        .h3 {\n            font-size: 2.25rem;\n            font-weight: bolder;\n            margin: 0;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
