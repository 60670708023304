// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1023px) {
  .create .reversable .bloc_container {
    margin-top: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/home.page.scss"],"names":[],"mappings":"AAGQ;EACI;IACI,gBAAA;EAFd;AACF","sourcesContent":["\n.create {\n    .reversable {\n        @media (max-width: 1023px) {\n            .bloc_container {\n                margin-top: 4rem;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
