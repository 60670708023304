import { iBlog } from "../interfaces/blog.interface";

export const BlogTop: iBlog = {
    link: "/blog/comment-definir-ses-boucles-le-guide-en-5-etapes",
    title: "Comment définir ses boucles&nbsp;:<strong> le guide en 5 étapes</strong>",
    baseline: `Tous nos conseils pour mieux définir ses boucles facilement&nbsp;: quels produits appliquer, dans quel ordre, quelles techniques et accessoires utiliser.  Pour des boucles hydratées et bien définies&nbsp;!`,
    cover: {
        jpg: "/assets/img/blog/define_loop.jpg",
        webp: "/assets/img/blog/define_loop.webp"
    },
    subheading: "Conseil d'Audrey",
}

