import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { iCollection } from 'src/app/interfaces/haircut.interface';
import { iInstagram } from 'src/app/interfaces/instagram.interface';
import { iInstagramToken } from 'src/app/interfaces/partner.interface';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {

  @Input() public file!: File;
  @Input() public supaUserId!: string;
  @Input() public loading: boolean = false;
  @Input() public show_instagram_grid: boolean = false;
  @Input() public fetchInstagramPostAfterRedirection: boolean = false;
  @Input() public instagram_token: iInstagramToken = { access_token: "", user_id: 0 };

  @Output() public resfreh: EventEmitter<null> = new EventEmitter();
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter();

  public instagram_urls: iInstagram[] = []
  public new_post_description: string = "";
  public collections: iCollection[] = [];
  public heic_error: boolean = false;
  public new_post_url: string = "";

  private post_from_instagram: boolean = false;
  private collection_id: string = "";
  private ratio: number = 1;

  constructor(
    private readonly _postService: PostService,
  ) { }

  ngOnInit(): void {
    this._postService.fetchCollection().then((snapshot) => {
      if (snapshot.data != null) {
        this.collections = snapshot.data;
        this.collection_id = this.collections[0].id;
        if (this.fetchInstagramPostAfterRedirection) {
          this.pictureFromInstagram();
        }
      }
    });
  }

  public fiLeBrowseHandler(event: any): void {
    this.post_from_instagram = false;
    if (!event.target.files || event.target.files.length === 0) {
      throw new Error('You must select an image to upload.');
    }

    this.file = event.target.files[0];
    if (this.file.type.includes("heic") || this.file.type.includes("HEIC")) {
      this.heic_error = true;
    } else {
      this.heic_error = false;
      let file_reader = new FileReader();
      file_reader.onload = () => {
        let img = new Image();
        img.onload = () => {
          this.ratio = img.height / img.width;
        };

        if (file_reader.result != null) return this.new_post_url = img.src = file_reader.result!.toString();
        else return null;
      }
      file_reader.readAsDataURL(this.file);
    }
  }

  public onBack(): void {
    this.show_instagram_grid = false;
  }

  public close(): void {
    this.show_instagram_grid = false;
    this.new_post_description = "";
    this.new_post_url = "";
    this.visibleChange.emit(true);
  }

  public selectToShare(instagram_post: iInstagram): void {
    this.new_post_description = instagram_post.caption;
    this.new_post_url = instagram_post.media_url;
    this.show_instagram_grid = false;
    this.post_from_instagram = true;
  }

  public selectCollection(collection_id: string): void {
    this.collection_id = collection_id;
  }

  public publish(form: NgForm): void {
    this.loading = true;
    if (this.post_from_instagram) {
      this.publishFromInstagram();
    } else if (this.file != null) {
      this.new_post_description = form.value["description"];

      const formData = new FormData();
      formData.append(`posts|${this.supaUserId}|1`, this.file);
      this._postService.postPictureFile(formData).subscribe(
        (thumbnails: any) => this.share(thumbnails),
        (err: any) => console.error(err.message),
      );

    }
  }

  public publishFromInstagram(): void {
    const filename: string = "insta_" + Date.now().toString();
    this._postService.uploadToBucket({
      media_url: this.new_post_url,
      supa_id: this.supaUserId,
      filename: filename,
    }).subscribe(
      (thumbnails: any) => this.share(thumbnails["data"]),
      (err: any) => console.error(err.message),
    );
  }

  public pictureFromInstagram(): void {
    if (this.instagram_token != null
      && this.instagram_token != undefined
      && this.instagram_token.user_id != undefined
      && this.instagram_token.access_token != undefined) {
      this._postService.fetchInstagramPost(this.instagram_token.user_id, this.instagram_token.access_token).subscribe(
        (res: any) => this.handleInstagramPost(res),
        (err: any) => console.log(err)
      );
    } else {
      this.oauthInstagram();
    }
    this.fetchInstagramPostAfterRedirection = false;
  }

  private oauthInstagram(): void {
    const url: string = "https://api.instagram.com/oauth/authorize?client_id=831270428086626&redirect_uri=https://haizzy.fr/account/&scope=user_profile,user_media&response_type=code";
    window.open(url, '_self', 'nofollow,noopener,noreferrer');
  }

  private handleInstagramPost(response: any) {
    if (response["data"]["data"] != undefined) {
      this.instagram_urls = response["data"]["data"].filter((data: { [x: string]: string; }) => data["media_type"] == "IMAGE");
      this.show_instagram_grid = true;
    } else {
      this.oauthInstagram();
    }
  }

  private share(thumbnails: any): void {
    this._postService.sharePicture({
      ratio: this.ratio,
      thumbnails: thumbnails,
      supa_id: this.supaUserId,
      collection_id: this.collection_id,
      description: this.new_post_description,
    }).then((snapshot) => {
      if (snapshot.data != null) this.resfreh.emit();
      this.close();
      this.loading = false;
    });
  }


}