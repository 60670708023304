import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { iArticle, iArticleType } from 'src/app/interfaces/article.interface';
import { Articles } from 'src/app/content/articles.content';
import { MetaService } from 'src/app/services/meta.service';
import { BlogTop } from 'src/app/content/blog.content';
import { iBlog } from 'src/app/interfaces/blog.interface';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.page.html',
  styleUrls: ['./blog.page.scss']
})
export class BlogPage implements OnInit {
  public blog_top: iBlog = BlogTop;
  public selected_filter: number = -1;
  public is_registered: boolean = false;
  public articles: iArticle[] = Articles;
  public articles_filter: iArticleType[] = [{
    index: -1,
    name: "Tout"
  }];
  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    this.articles = Articles.filter((item) => {
      return item.show !== false
    });

    this._title.setTitle("Haizzy | Retrouvez tous les articles");
    this._meta.updateTag({
      name: "description",
      content: "Des infos, des idées et des histoires pour cheveux ondulés, bouclés, frisés et crépus."
    });
    this.initFilter();
  }

  initFilter(): void {
    const articles_type: iArticleType[] = this.articles.map((article) => article.type);
    const map = new Map();
    for (const type of articles_type) {
      if (!map.has(type.index)) {
        map.set(type.index, true);
        this.articles_filter.push({
          index: type.index,
          name: type.name
        });
      }
    }
  }

  selectFilter(index: number | null, value: string | null): void {
    if (typeof (index) == "number") {
      this.selected_filter = index;
    } else if (value != null) {
      this.selected_filter = parseInt(value);
    }
    this.articles = Articles.filter((item) => {
      if (this.selected_filter != -1) {
        return item.show !== false && item.type.index == this.selected_filter;
      } else {
        return item.show !== false;
      }
    });
  }

}


