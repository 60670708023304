import { Component, OnInit } from '@angular/core';
import { iFaq } from 'src/app/interfaces/faq.interface';
import * as faq from 'src/app/content/faq.content';
import { iShopProduct } from 'src/app/interfaces/shop.interface';
import { MetaService } from 'src/app/services/meta.service';
import { ShopService } from 'src/app/services/shop.service';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-shop',
  templateUrl: './shop.page.html',
  styleUrls: ['./shop.page.scss']
})

export class ShopPage implements OnInit {

  private url: string = "";
  public is_open: boolean = false;
  public brand_filter: string[] = [];
  public brand_selected: string[] = [];
  public faq: iFaq[] = [faq.Haizzy[0]];
  public is_registered: boolean = false;
  public modal_is_open: boolean = false;
  public selected_product_id: string = "";
  public shop_products: iShopProduct[] = [];
  public all_shop_products: iShopProduct[] = [];

  constructor(
    private readonly _meta: Meta,
    private readonly _title: Title,
    private readonly _metaService: MetaService,
    private readonly _shopService: ShopService,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    this.setMetadata();
    this.url = window.location.href;
    this.fetchShopItem();
  }

  private fetchShopItem(): void {
    this._shopService.fetchShopItems().then((snapshot) => {
      if (snapshot.data != null) {
        this.handleResponse(snapshot.data);
      } else {
        console.error("No item found!!");
      }
    });
  }

  private handleResponse(data: any): void {
    this.all_shop_products = data;
    this.shop_products = data;
    this.setBrandFilter();
  }

  public toogle(): void {
    this.is_open = !this.is_open;
  }

  public setBrandFilter(): void {
    const map = new Map();
    for (const type of this.shop_products) {
      if (!map.has(type.brand_name)) {
        map.set(type.brand_name, true);
        this.brand_filter.push(type.brand_name);
      }
    }
  }

  public applyBrandFilter(name: string, checked: boolean): void {
    if (checked && !this.brand_selected.includes(name)) {
      this.brand_selected.push(name);
    } else if (!checked && this.brand_selected.includes(name)) {
      const index: number = this.brand_selected.indexOf(name);
      this.brand_selected.splice(index, 1);
    }

    if (!checked && this.brand_selected.length == 0) {
      this.shop_products = this.all_shop_products;
    } else {
      this.shop_products = this.all_shop_products.filter(product => this.brand_selected.includes(product.brand_name));
    }
  }

  public showDetail(id: string): void {
    this.selected_product_id = id;
    window.history.pushState({}, '', `shop/${id}`);
    this.modal_is_open = true;
  }

  public close(): void {
    window.history.pushState({}, '', this.url);
    this.selected_product_id = "";
    this.modal_is_open = false;
  }



  public setMetadata(): void {
    const title: string = "Haizzy | La boutique";
    const description: string = "Tout ce que dont vous avez besoin pour vos cheveux texturés se retrouve sur la boutiuqe Haizzy.";

    this._title.setTitle(title);
    this._meta.updateTag({
      name: "description",
      content: description,
    })
    this._meta.updateTag({
      property: "og:title",
      content: title,
    });
    this._meta.updateTag({
      property: "og:description",
      content: description,
    })
    this._meta.updateTag({
      property: "og:url",
      content: `https://haizzy.fr/shop`,
    })

  }


}
