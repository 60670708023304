import { Component, OnInit } from '@angular/core';
import { iFaq } from 'src/app/interfaces/faq.interface';
import * as faq from 'src/app/content/faq.content';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})
export class HomePage implements OnInit {

  public is_registered: boolean = false;
  public faq: iFaq[] = [
    faq.Haizzy[0],
    faq.Haizzy[1],
    faq.Common[0],
    faq.Common[1],
    faq.Customer[0],
    faq.Customer[3],
    faq.Common[2],
  ];

  constructor(
    private readonly _metaService: MetaService) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
  }
  /*
  * Jpin community => Page hairdresser
  * Booking => Page how we book
  * Create a relationship => Page Testimonials
  * Why are you waiting ?
  */

}
