import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { iFaq } from 'src/app/interfaces/faq.interface';
import * as faq from 'src/app/content/faq.content';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss']
})
export class FaqPage implements OnInit {
  public is_registered: boolean = false;

  public about_haizzy: iFaq[] = faq.Haizzy;
  public about_partner: iFaq[] = faq.Partner;
  public about_customer: iFaq[] = faq.Customer;

  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {

    this._title.setTitle("Haizzy | Questions fréquentes");
    this._meta.updateTag({
      name: "description",
      content: "Jetez un œil à nos questions fréquentes pour savoir comment et pourquoi réserver une coiffeuse Afro à domicile proche de chez vous avec Haizzy."
    });
  }

}
