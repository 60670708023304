// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.second__layout {
  width: 100%;
  padding: 0 2rem;
}
.second__layout .main-wrapper .agrs__wrapper {
  text-align: center;
}
.second__layout .main-wrapper .agrs__wrapper ul li {
  padding: 2rem;
  display: inline-block;
  vertical-align: top;
}
@media (min-width: 650px) {
  .second__layout .main-wrapper .agrs__wrapper ul li {
    width: 33.333333%;
  }
}
@media (max-width: 383px) {
  .second__layout .main-wrapper .agrs__wrapper ul li {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .second__layout .main-wrapper .agrs__wrapper ul li .agrs__wrapper-content {
    margin-left: 1em;
    text-align: left;
  }
  .second__layout .main-wrapper .agrs__wrapper ul li .agrs__wrapper-content .h5, .second__layout .main-wrapper .agrs__wrapper ul li .agrs__wrapper-content p {
    text-align: left;
    margin-bottom: 0;
  }
}
.second__layout .main-wrapper .agrs__wrapper ul li svg {
  width: 5rem;
  height: 5rem;
}
@media (prefers-color-scheme: dark) {
  .second__layout .main-wrapper .agrs__wrapper ul li svg circle {
    opacity: 0.7;
  }
}
.second__layout .main-wrapper .agrs__wrapper ul li p {
  max-width: 28rem;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/quality-icons/quality-icons.component.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,eAAA;AAAJ;AAEQ;EACI,kBAAA;AAAZ;AAEgB;EACI,aAAA;EACA,qBAAA;EACA,mBAAA;AAApB;AACoB;EAJJ;IAKQ,iBAAA;EAEtB;AACF;AADoB;EAPJ;IAQQ,WAAA;IACA,aAAA;IACA,mBAAA;EAItB;EAHsB;IACI,gBAAA;IACA,gBAAA;EAK1B;EAJ0B;IACI,gBAAA;IACA,gBAAA;EAM9B;AACF;AAHoB;EACI,WAAA;EACA,YAAA;AAKxB;AAJwB;EACI;IACI,YAAA;EAM9B;AACF;AAHoB;EACI,gBAAA;EACA,YAAA;AAKxB","sourcesContent":["\n.second__layout {\n    width: 100%;\n    padding: 0 2rem;\n    .main-wrapper {\n        .agrs__wrapper {\n            text-align: center;\n            ul {\n                li {\n                    padding: 2rem;\n                    display: inline-block;\n                    vertical-align: top;\n                    @media (min-width: 650px) {\n                        width: 33.333333%; \n                      }\n                    @media (max-width: 383px) {\n                        width: 100%; \n                        display: flex;\n                        flex-direction: row;\n                        .agrs__wrapper-content {\n                            margin-left: 1em;\n                            text-align: left;\n                            .h5, p {\n                                text-align: left;\n                                margin-bottom: 0;\n                            }\n                        }\n                    }\n                    svg {\n                        width: 5rem;\n                        height: 5rem;\n                        @media (prefers-color-scheme: dark) {\n                            circle {\n                                opacity: 0.7;\n                            }\n                        }\n                    }\n                    p {\n                        max-width: 28rem;\n                        margin: auto;\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
