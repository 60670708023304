import { Haircuts } from 'src/app/content/haircuts.content';
import { MetaService } from 'src/app/services/meta.service';
import { iHaircut } from 'src/app/interfaces/haircut.interface';
import { Title, Meta } from '@angular/platform-browser';
import * as faq from 'src/app/content/faq.content';
import { Component, OnInit } from '@angular/core';
import { iFaq } from 'src/app/interfaces/faq.interface';


@Component({
  selector: 'app-haircuts',
  templateUrl: './haircuts.page.html',
  styleUrls: ['./haircuts.page.scss']
})
export class HaircutsPage implements OnInit {
  public is_registered: boolean = false;
  public haircuts: iHaircut[] = Haircuts;
  public about_haircut: iFaq[] = faq.Haircut;
  public linkTo: string = "/faq#about_customer";
  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {

    this._title.setTitle("Haizzy | Les coupes Afro de nos coiffeuses à domicile.");
    this._meta.updateTag({
      name: "description",
      content: "Nos coiffeuses Afro sont professionnelles et pationnées, elles saurait réaliser les coupes dont vous avez besoins, box braids, twist, cornrows ou dreadlocks."
    });
  }

}
