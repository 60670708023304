import { iHaircut } from 'src/app/interfaces/haircut.interface';
export const Haircuts: iHaircut[] = [
    {
        price: {
            min: '20',
            max: '90'
        },
        title: 'Soins cheveux naturels',
        picture: {
            webp: '/assets/img/haircuts/haircuts-natural.webp',
            jpeg: '/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_200.jpg 200w,/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_589.jpg 589w,/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_829.jpg 829w,/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_1043.jpg 1043w,/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_1242.jpg 1242w,/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_1400.jpg 1400w',
            img: '/assets/img/haircuts/haircuts-natural_ahjvjj_c_scale,w_1400.jpg',

        },
        description: 'Vos cheveux sont abîmés, secs, cassants ou d’une nature fragile ? Rassurez-vous, il existe des astuces infaillibles afin de leur redonner une bonne santé. Ça passe par de bonnes habitudes et des soins adaptés. Accordez-vous de temps en temps, de petits moments pour chouchouter vos cheveux. Prendre de bonnes habitudes et mettre en place une routine qui vous correspond, c\'est le secret d\'une chevelure éclatante de beauté.',
    },
    {
        price: {
            min: '20',
            max: '60'
        },
        title: 'Coupe enfant',
        picture: {
            webp: '/assets/img/haircuts/haircuts-kid.webp',
            jpeg: '/assets/img/haircuts/haircuts-kid_ek4q9t_c_scale,w_200.jpg 200w,/assets/img/haircuts/haircuts-kid_ek4q9t_c_scale,w_507.jpg 507w,/assets/img/haircuts/haircuts-kid_ek4q9t_c_scale,w_718.jpg 718w,/assets/img/haircuts/haircuts-kid_ek4q9t_c_scale,w_1098.jpg 1098w,/assets/img/haircuts/haircuts-kid_ek4q9t_c_scale,w_1289.jpg 1289w,/assets/img/haircuts/haircuts-kid_ek4q9t_c_scale,w_1400.jpg 1400w',
            img: '/assets/img/haircuts-kid_ek4q9t_c_scale.jpg',
        },
        description: 'Nos coiffeuses sont capables de réaliser des nattes, des tresses, des minis-locks pour enfant. Nous savons qu\'il n\'est pas toujours évident d\'amener son enfant dans un salon de coiffure Afro, c\'est pourquoi nous vous offrons la possibilité d\'avoir une coiffeuse Afro à domicile pour votre enfant. Cependant, nous vous conseillons d\'éviter les coupes élaborées avec un enfant en bas âge, leur cheveux sont encore fragiles.'
    },
    {
        price: {
            min: '20',
            max: '70'
        },
        title: 'Cornrows ou Tresses',
        picture: {
            webp: '/assets/img/haircuts/haircuts-cornrows.webp',
            jpeg: '/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_200.jpg 200w,/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_567.jpg 567w,/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_826.jpg 826w,/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_1035.jpg 1035w,/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_1271.jpg 1271w,/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_1400.jpg 1400w',
            img: '/assets/img/haircuts/haircuts-cornrows_qkxc2k_c_scale,w_1400.jpg',
        },
        description: 'C\'est l\'un des styles les plus connus pour les cheveux Afro. Ces tresses en relief sont formées en utilisant la technique qui tresse au plus près du cuir chevelu, la peau est souvent exposée. Considéré comme un style protecteur, vous pouvez laisser les cornrows pendant plusieurs semaines si vous en prenez bien soin et que vous gardez votre cuir chevelu hydraté.',
    },
    {
        price: {
            min: '40',
            max: '95'
        },
        title: 'Braids ou Nattes',
        picture: {
            webp: '/assets/img/haircuts/haircuts-braids.webp',
            jpeg: '/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_200.jpg 200w,/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_591.jpg 591w,/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_843.jpg 843w,/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_1058.jpg 1058w,/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_1278.jpg 1278w,/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_1400.jpg 1400w"',
            img: '/assets/img/haircuts/haircuts-braids_smq6kx_c_scale,w_1400.jpg',
        },
        description: 'Cette coiffure est l\'une des plus polyvalentes, allant de motifs incroyablement complexes à de simples braids. Les braids sont également idéales pour la plupart des occasions. Que vous soyez au bureau, à l’école, à la salle de sport ou même à un mariage, il existe une coiffure braids qui vous convient. Mieux encore, les braids peuvent protéger vos cheveux et vous aider à faire pousser votre crinière.'
    },
    {
        price: {
            min: '50',
            max: '75'
        },
        title: 'Twist ou Vanilles',
        picture: {
            webp: '/assets/img/haircuts/haircuts-vanille.webp',
            jpeg: '/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_200.jpg 200w,/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_574.jpg 574w,/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_811.jpg 811w,/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_996.jpg 996w,/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_1179.jpg 1179w,/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_1345.jpg 1345w,/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_1400.jpg 1400w',
            img: '/assets/img/haircuts/haircuts-vanille_rmmtbl_c_scale,w_1400.jpg',
        },
        description: 'Les vanilles sont avec les box braids, une coiffure très répandue chez les femmes aux cheveux crépus et frisés. Les twist (ou vanilles) offrent une grande possibilité de coiffure : en queue-de-cheval (ponytail), en chignon ou simplement lâchées. Comme les braids, les vanilles offrent une grande flexibilité puisqu\'elles se portent aussi à toutes les occasions et protègent également vos cheveux, par contre elles demandent beaucoup d\'entretien.',
    },
    {
        price: {
            min: '60',
            max: '90'
        },
        title: 'Fausses locks',
        picture: {
            webp: '/assets/img/haircuts/haircuts-dreadlocks.webp',
            jpeg: '/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_200.jpg 200w,/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_600.jpg 600w,/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_858.jpg 858w,/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_1126.jpg 1126w,/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_1289.jpg 1289w,/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_1400.jpg 1400w',
            img: '/assets/img/haircuts/haircuts-dreadlocks_k2mujk_c_scale,w_1400.jpg',
        },
        description: 'Ancestrale, culturelle, engagée, protectrice ou tout simplement à la mode. Les dreadLocks ont su s\'adapter aux différentes époques. Qu\'elle soit confectionnée avec vos cheveux ou des mèches, cette coupe s\'adapte à votre humeur du jour. Lâchées, attachées, ondulées ou même tressées, locks à la Rihanna ou Bob Marley, c\'est vous qui décidez.',
    },
]