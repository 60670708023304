import { iFaq } from 'src/app/interfaces/faq.interface';
export const Haizzy: iFaq[] = [
    {
        question: 'Est-ce que <span class="brand">Haizzy</span> est un salon de coiffure&nbsp;?',
        answer: 'Non, nous croyons que le modèle du salon de coiffure doit évoluer et laisser place à ce qui est important&nbsp;: La relation entre des coiffeuses qui connaissent les spécificités des coupes Afros et les leurs clientes.',
        is_open: false
    },
    {
        question: 'Est-ce que <span class="brand">Haizzy</span> est réservée aux femmes et aux enfant&nbsp;?',
        answer: 'Pour le moment nous nous adressons uniquement aux femmes qui souhaitent une coupe Afro, pour elles et/ou leurs enfants. <a href="/blog/pourquoi-ne-s-adresser-qu-aux-femmes-et-aux-coupes-afros" target="_self" rel="follow" aria-label="Lire l\'article pourquoi ne s’adresser qu’aux femmes et aux coupes afros">Lisez notre article à ce propos</a>',
        is_open: false
    },
    {
        question: 'Quand est-ce que <span class="brand">Haizzy</span> sera disponible&nbsp;?',
        answer: 'Nos services seront disponibles très prochainement&nbsp;! Pensez à vous préinscrire pour les découvrir avant.',
        is_open: false
    },
]

export const Customer: iFaq[] = [
    {
        question: 'Il y a t-il des coiffeuses Afro près de chez moi&nbsp;?',
        answer: 'Idéalement oui. Envoyez-nous un mail avec le nom de votre ville et le code postal si vous ne trouvez personne à proximité, nous recruterons de nouvelles partenaires&nbsp;!',
        is_open: false
    },
    {
        question: 'Comment réserver une coiffeuse Afro&nbsp;?',
        answer: 'En quelques étapes depuis l\'application <span class="brand">Haizzy</span>, vous accédez à l\'ensemble des prestations. Il vous suffit de choisir la date qui vous arrange, de préciser votre besoin et de confirmer la réservation.',
        is_open: false
    },
    {
        question: 'Combien ça me coûte&nbsp;?',
        answer: 'Le tarif vous sera proposé avant la réservation par votre coiffeuse selon les critères suivants&nbsp;: le marché, la complexité et la durée. <a href="/haircuts" target="_self" rel="follow" aria-label="Découvrez à nos coupes Afro">Découvrez nos coupes Afro pour en savoir plus</a>',
        is_open: false
    },
    {
        question: 'Quels sont les modes de paiement acceptés&nbsp;?',
        answer: 'Pour le moment nous n\'acceptons que les réglements par carte bancaire depuis l\'application <span class="brand">Haizzy</span.',
        is_open: false
    },
    {
        question: 'Est-ce que les coiffeuses fournissent les mèches&nbsp;?',
        answer: 'Les coiffeuses <span class="brand">Haizzy</span> ne fournissent pas les mèches. Par contre, elles vous conseilleront sur les boutiques, les types de mèche et le nombre de paquets nécessaires.',
        is_open: false
    },
    {
        question: '<span class="brand">Haizzy</span> est-elle destinée uniquement aux femmes et enfants aux cheveux Afro&nbsp;?',
        answer: 'Les coiffeuses <span class="brand">Haizzy</span> saurons dompter vos cheveux, peu importe leur type&nbsp;!',
        is_open: false
    },
]

export const Partner: iFaq[] = [
    {
        question: 'Comment devenir coiffeuse partenaire&nbsp;?',
        answer: 'Vous pouvez vous préinscrire dès maintenant, nous vous informerons par mail des étapes à suivre. Vous hésitez&nbsp;? N\'hésitez pas à lire notre article sur <a href="/blog/pourquoi-devrais-je-devenir-une-coiffeuse-partenaire-haizzy" target="_self" rel="follow" aria-label="Lire l\'article">pourquoi nous rejoindre&nbsp;?</a>',
        is_open: false
    },
    {
        question: 'Faut-il avoir un statut d\'auto-entreprise&nbsp;?',
        answer: 'Oui, c\'est indispensable. Vous pouvez vous inscrire gratuitement au service de l\'URSSAF. N\'hésitez pas à lire notre article sur <a href="/blog/quelles-sont-les-procedures-pour-devenir-coiffeuse-a-domicile" target="_self" rel="follow" aria-label="Lire l\'article">les procédures pour devenir coiffeuse à domicile.</a>',
        is_open: false
    },
    {
        question: 'Combien gagne une coiffeuse partenaire&nbsp;?',
        answer: 'Les revenus d\'une coiffeuse dépendent de son activité. Par exemple, une coiffeuse qui réalise 3 coupes par jour d\'une valeur de 60€ pendant 20 jours dans une ville de taille moyenne, recevra un revenu net de 2&nbsp;200€. <a href="/partner#simulation" target="_self" rel="follow">Essayez le simulateur de gains.</a>',
        is_open: false
    },
    {
        question: 'Est-ce que le matériel est fourni&nbsp;?',
        answer: 'Chaque coiffeuse partenaire doit apporter son propre matériel.',
        is_open: false
    },]

export const Common: iFaq[] = [
    {
        question: 'Concrètement, comment ça marche&nbsp;?',
        answer: 'Si vous souhaitez être cliente, depuis notre application mobile <span class="brand">Haizzy</span>, vous pouvez choisir une coupe, la réserver, l\'annuler et voir les profils de nos coiffeuses Afro partenaires. Si vous souhaitez être coiffeuse partenaire, vous devez être <a href="https://espace-autoentrepreneur.com/ruche/article/coiffeur-auto-entrepreneur" target="new" rel="noopener">auto-entrepreneuse</a> et posséder un numéro de SIRET. Nous mettons à votre disposition une application mobile dédiée où vous pouvez accepter, refuser et planifier vos rendez-vous.',
        is_open: false
    },
    {
        question: 'Combien ça me coûte&nbsp;?',
        answer: 'Les prix des coupes sont fixés par nos équipes en fonction du marché. Nous proposons un service aussi simple qu\'un salon de coiffure Afro. En tant que cliente, vous ne payez que le prix affiché lors de la réservation. En tant que coiffeuse Afro partenaire, plus votre réputation est élevée, plus vos revenus sont intéressants.',
        is_open: false
    },
    {
        question: 'Et les coiffeuses Afro partenaires, comment sont elles choisies&nbsp;?',
        answer: 'Nous souhaitons donner l\'opportunité à chaque coiffeuse de partager sa passion et son talent avec notre communauté. Nous faisons confiance à notre communauté pour rendre populaires les meilleures coiffeuses.',
        is_open: false
    }
]

export const Haircut: iFaq[] = [
    {
        question: 'Comment obtenir la coupe qui me convient&nbsp;?',
        answer: 'Depuis l\'application mobile <span class="brand">Haizzy</span>, vous avez la possibilité de discuter avec la coiffeuse que vous avez sélectionnée. Vous pouvez donc préciser votre besoin et déterminer la coupe qui vous convient.',
        is_open: false
    },
    {
        question: 'Où se déroule la coupe&nbsp;?',
        answer: 'C\'est vous, cliente, qui décidez l\'endroit qui vous semble le plus confortable (votre salon, la salle de bain ou autre). Nous conseillons d\'éviter les moquettes et de prévoir une table pour permettre à votre coiffeuse de poser son matériel.',
        is_open: false
    }
]

export const BecomePartner: iFaq[] = [
    {
        question: 'Quelles sont les conditions requises pour devenir coiffeuse partenaire&nbsp;?',
        answer: 'Vous avez besoin d\'un document pour prouver votre droit de travailler en France&nbsp;: <br/> - Un passeport EEA<br/>- Ou une carte d\'identité française<br/>- Ou un permis de conduire français<br/><br/>Et de documents administratifs pour débuter votre activité de coiffeuse indépendante&nbsp;:<br/>- Votre statut d’auto-entrepreneuse<br/>- Votre extrait Kbis <br/> - Votre attestation de vigilance<br/>N\'hésitez pas à lire notre article sur <a href="/blog/quelles-sont-les-procedures-pour-devenir-coiffeuse-a-domicile" target="_self" rel="follow" aria-label="Lire l\'article">les procédures pour devenir coiffeuse à domicile.</a>',
        is_open: false
    },
    Partner[2],
    {
        question: 'Comment suis-je payée&nbsp;?',
        answer: 'Vous êtes payées pour chaque prestation réalisée. Les frais de fonctionnement de notre plateforme de mise en relation sont de 17%. Vous définissez le prix final de la prestation avant d’accepter la demande d\'une cliente.<br/><br/>Un virement sera effectué un mardi sur deux. Si vous souhaitez obtenir vos revenus plus tôt, vous pouvez utiliser la fonctionnalité "Transférer" dans votre application.',
        is_open: false
    },
]


export const HaizzyWin: iFaq[] = [
    {
        question: 'Qui peut participer au concours&nbsp;?',
        answer: 'Le concours est ouvert aux résidents de France (DROM-COM inclus), Belgique, Luxembourg et Suisse âgés de 15 ans minimum. Veuillez consulter <a href="/cgu" target="_self" rel="follow" aria-label="Accéder aux CGU">les conditions générales</a> pour plus de détails.',
        is_open: false
    },
    {
        question: 'Comment puis-je participer au concours&nbsp;?',
        answer: 'Pour participer, téléchargez et installez notre application <span class="brand">Haizzy</span> depuis le <a href=\'https://play.google.com/store/apps/details?id=com.gazelle.calaopartner&hl=fr\' target="_blank" aria-label="Disponible sur Google Play" rel="me nofollow noopener noreferrer"">Google Play Store</a> ou sur <a href="https://itunes.apple.com/fr/app/haizzy/id1546883701" target="_blank" aria-label="Disponible sur l\'App Store" rel="me nofollow noopener noreferrer"> Apple App Store</a>, créez un compte utilisateur et publiez votre photo.',
        is_open: false
    },
    {
        question: 'Est-ce que je peux participer plusieurs fois&nbsp;?',
        answer: 'Non, chaque compte utilisateur est autorisé à soumettre une seule participation.',
        is_open: false
    },
    {
        question: 'Comment saurai-je si j\'ai gagné&nbsp;?',
        answer: 'Les gagnants seront annoncés sur notre <a href="https://www.instagram.com/haizzy.fr/" target="_blank" aria-label="Voir notre instagram" rel="me nofollow noopener noreferrer">compte Instagram </a> et via une notification dans l\'application. Nous vous contacterons également par message direct.',
        is_open: false
    },
    {
        question: 'Quels sont les critères de sélection des gagnants&nbsp;?',
        answer: 'Les 3 participants dont les photos auront le plus de "like" seront sélectionnées',
        is_open: false
    },
    {
        question: 'Comment seront distribués les prix&nbsp;?',
        answer: 'Les prix seront distribués selon les places gagnantes. La carte cadeaux Amazon sera envoyée par e-mail, le bonnet en satin et les produits seront envoyés par voie postale.',
        is_open: false
    },
    {
        question: 'Est-ce que je peux utiliser des extensions capillaires pour ma coiffure&nbsp;?',
        answer: 'Oui, vous pouvez utiliser des extensions capillaires pour votre coiffure. L\'important est que la coiffure reflète le thème de la rentrée et soit créative.',
        is_open: false
    },
    {
        question: 'Qu\'advient-il de ma photo après le concours&nbsp;?',
        answer: 'En participant au concours, vous autorisez <span class="brand">Haizzy</span> à utiliser votre photo à des fins de promotion sur les médias sociaux et le site web de l\'entreprise.',
        is_open: false
    },
    {
        question: 'Puis-je modifier ma participation après l\'avoir soumise&nbsp;?',
        answer: 'Malheureusement, une fois votre participation soumise, vous ne pourrez pas la modifier. Veuillez vous assurer de bien télécharger la photo que vous souhaitez soumettre.',
        is_open: false
    },
    {
        question: 'Où puis-je obtenir de l\'aide si j\'ai des questions supplémentaires&nbsp;?',
        answer: 'Si vous avez des questions supplémentaires, n\'hésitez pas à nous contacter à l\'adresse e-mail <a href:"mailto:contact@haizzy.fr">contact@<span class="brand">Haizzy</span>.fr</a> ou via la messagerie depuis l\'application.',
        is_open: false
    },
]
