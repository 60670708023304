import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.page.html',
  styleUrls: ['./cgu.page.scss']
})
export class CguPage implements OnInit {
  public is_registered: boolean = false;
  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {

    this._title.setTitle("Haizzy | Nos conditions générales d'utilisation");
    this._meta.updateTag({
      name: "description",
      content: "Les conditions générales d'utilisation de notre site sont utilisées pour encadrer la façon dont vous êtes amenés à utiliser nos différents services."
    });
  }

}
