// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  background-color: var(--stats-background-color);
}
.layout .full-width {
  width: 100%;
}
.layout .full-width h1 {
  margin-bottom: 0;
}
.layout .stats-section {
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 1023px) {
  .layout .stats-section iframe {
    height: 1900px;
  }
}
@media (max-width: 1023px) {
  .layout .stats-section iframe {
    height: 2955px;
  }
}
@media (max-width: 376px) {
  .layout .stats-section iframe {
    height: 2999px;
  }
}
@media (max-width: 321px) {
  .layout .stats-section iframe {
    height: 3150px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/stats/stats.page.scss"],"names":[],"mappings":"AAAA;EACI,+CAAA;AACJ;AACI;EACI,WAAA;AACR;AACQ;EACI,gBAAA;AACZ;AAGI;EACI,cAAA;EACA,iBAAA;AADR;AAIQ;EACI;IACI,cAAA;EAFd;AACF;AAKQ;EACI;IACI,cAAA;EAHd;AACF;AAMQ;EACI;IACI,cAAA;EAJd;AACF;AAOQ;EACI;IACI,cAAA;EALd;AACF","sourcesContent":[".layout {\n    background-color: var(--stats-background-color);\n\n    .full-width {\n        width: 100%;\n\n        h1 {\n            margin-bottom: 0;\n        }\n    }\n\n    .stats-section {\n        padding-top: 0;\n        padding-bottom: 0;\n\n\n        @media (min-width: 1023px) {\n            iframe {\n                height: 1900px;\n            }\n        }\n\n        @media (max-width: 1023px) {\n            iframe {\n                height: 2955px;\n            }\n        }\n\n        @media (max-width: 376px) {\n            iframe {\n                height: 2999px;\n            }\n        }\n\n        @media (max-width: 321px) {\n            iframe {\n                height: 3150px;\n            }\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
