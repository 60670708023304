import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// PAGES
import { FaqPage } from './pages/faq/faq.page';
import { CguPage } from './pages/cgu/cgu.page';
import { HomePage } from './pages/home/home.page';
import { BlogPage } from './pages/blog/blog.page';
import { AboutPage } from './pages/about/about.page';
import { PrivacyPage } from './pages/privacy/privacy.page';
import { PartnerPage } from './pages/partner/partner.page';
import { ArticlePage } from './pages/article/article.page';
import { HaircutsPage } from './pages/haircuts/haircuts.page';
import { NotFoundPage } from './pages/not-found/not-found.page';
import { PostPage } from './pages/post/post.page';
import { PartnerProfilPage } from './pages/partner-profil/partner-profil.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { ShopPage } from './pages/shop/shop.page';
import { ProductPage } from './pages/product/product.page';
import { OauthPage } from './pages/oauth/oauth.page';
import { AccountPage } from './pages/account/account.page';
import { OauthGuard } from './guard/oauth.guard';
import { StatsPage } from './pages/stats/stats.page';
import { ConcoursPage } from './pages/concours/concours.page';


const routes: Routes = [{
  path: '',
  component: HomePage
},
{
  path: 'about',
  component: AboutPage
},
{
  path: 'blog',
  component: BlogPage
},
{
  path: 'oauth',
  component: OauthPage,
},
{
  path: 'blog/:slug',
  component: ArticlePage
},
{
  path: 'conseil/:slug',
  component: ArticlePage
},
{
  path: 'post/:post',
  component: PostPage
},
{
  path: 'forgot-password',
  component: ForgotPasswordPage
},
{
  path: 'forgot-password/:token',
  component: ForgotPasswordPage
},
{
  path: 'haircuts',
  component: HaircutsPage
},
{
  path: 'faq',
  component: FaqPage
},
{
  path: 'oauth',
  component: OauthPage
},
{
  path: 'account',
  component: AccountPage,
  canActivate: [
    OauthGuard
  ]
},
{
  path: 'shop',
  component: ShopPage
},
{
  path: 'shop/:product',
  component: ProductPage
},
{
  path: 'partner',
  component: PartnerPage,
},
{
  path: 'partner/:username',
  component: PartnerProfilPage
},
{
  path: 'partner/:username/:post',
  component: PostPage
},
{
  path: 'post/:post',
  component: PostPage
},
{
  path: 'privacy',
  component: PrivacyPage
},
{
  path: 'cgu',
  component: CguPage
},
{
  path: 'stats',
  component: StatsPage
},
{
  path: '404',
  component: NotFoundPage
},
{
  path: '**',
  component: NotFoundPage
},];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'enabled',
      // scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 90]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
