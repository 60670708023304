import { Component, OnInit } from '@angular/core';
import { iFaq } from 'src/app/interfaces/faq.interface';
import * as faq from 'src/app/content/faq.content';
import * as simulation from 'src/app/content/simulation.content';
import { MetaService } from 'src/app/services/meta.service';
import SwiperCore, {
  Pagination
} from 'swiper';
import { Meta, Title } from '@angular/platform-browser';
import { iSimulation } from 'src/app/interfaces/simulation.interface';

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-partner',
  templateUrl: './partner.page.html',
  styleUrls: ['./partner.page.scss']
})
export class PartnerPage implements OnInit {

  public break: any;
  public simulation: number = 0;
  public is_registered: boolean = false;
  public about_partner: iFaq[] = faq.BecomePartner;
  public states: Array<number> = [0, 0, 0];
  public values: Array<iSimulation[]> = [
    simulation.CitySizeSimulation,
    simulation.WorkDaysSimulation,
    simulation.HaircutLevelSimulation
  ];

  public linkTo: string = "/faq#about_hairdresser";

  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {

    this._title.setTitle("Haizzy | Devenez coiffeuse Afro partenaire");
    this._meta.updateTag({
      name: "description",
      content: "Rejoignez une communauté où des centaines de coiffeuses Afro indépendantes ont fait le choix de valoriser leur talent sans aucune contraintre."
    });
    for (let index = 0; index < this.values.length; index++) this.updateGain(1, index);
    this.setBreakpoint();
  }

  updateGain(index: number, target: number): void {
    this.values[target].forEach((el, i: number) => {
      el.selected = index === i ? true : false;
    });

    this.values[target].map(item => {
      if (item.selected === true) {
        this.states[target] = item.value;
      }
    });

    const brut = (((this.states[2] + this.states[0]) * 3) * this.states[1]) * 4;
    const lessFees = brut - (17 * brut / 100);
    this.simulation = Math.round(lessFees - (25 * lessFees / 100));
  }

  setBreakpoint(): void {
    this.break = {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    };
  }

}
