import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { iFaq } from 'src/app/interfaces/faq.interface';
import * as faq from 'src/app/content/faq.content';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-concours',
  templateUrl: './concours.page.html',
  styleUrls: ['./concours.page.scss']
})
export class ConcoursPage implements OnInit {

  public about_concours: iFaq[] = faq.HaizzyWin;
  constructor(
    private readonly _metaService: MetaService,
    private readonly _title: Title,
    private readonly _meta: Meta,
  ) {
    this._metaService.createCanonicalURL();
  }

  ngOnInit(): void {
    const title: string = "Concours 100€ à gagner";
    const description: string = "Participez à notre concours, une carte cadeau Amazon de 100€ à gagner";
    this._title.setTitle(title);
    this._meta.updateTag({
      name: "description",
      content: description
    });
    this._meta.updateTag({
      property: "og:url",
      content: `https://haizzy.fr/concours`
    });
    this._meta.updateTag({
      property: "og:title",
      content: title
    });
    this._meta.updateTag({
      property: "og:description",
      content: description
    });
    this._meta.updateTag({
      property: "og:image",
      content: `https://haizzy.fr/assets/img/concours/haizzy_win_09_23.jpg`
    });
  }

}
