// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar {
  width: 100%;
  max-width: 56rem;
}
.calendar__header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 80em) {
  .calendar__header {
    justify-content: center;
  }
}
.calendar__header h3 {
  margin: 0;
  width: 23rem;
  text-align: center;
}
.calendar__header .calender__header--btn {
  width: 3.2rem;
  display: inline-block;
  vertical-align: middle;
}
.calendar__header .calender__header--btn button {
  padding: 0;
  display: flex;
  width: 3.2rem;
  height: 3.2rem;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.calendar__header .calender__header--btn button:hover {
  background: var(--second-background-color);
}
.calendar .day {
  width: 14.2857142857%;
  height: 4rem;
  display: inline-block;
  vertical-align: top;
  line-height: 4rem;
}
.calendar .day.unavailable {
  opacity: 0.4;
}
@media (max-width: 80em) {
  .calendar .day {
    text-align: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/calendar/calendar.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,gBAAA;AAAF;AACI;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACN;AAAM;EAJF;IAKI,uBAAA;EAGN;AACF;AAFM;EACE,SAAA;EACA,YAAA;EACA,kBAAA;AAIR;AAFM;EACE,aAAA;EACA,qBAAA;EACA,sBAAA;AAIR;AAHQ;EACE,UAAA;EACA,aAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;AAKV;AAJU;EACE,0CAAA;AAMZ;AADI;EACE,qBAAA;EACA,YAAA;EACA,qBAAA;EACA,mBAAA;EACA,iBAAA;AAGN;AAFM;EACE,YAAA;AAIR;AAFM;EATF;IAS4B,kBAAA;EAM9B;AACF","sourcesContent":["\n.calendar {\n  width: 100%;\n  max-width: 56rem;\n    &__header {\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      @media (max-width: 80em) {\n        justify-content: center;\n      }\n      h3 {\n        margin: 0;\n        width: 23rem;\n        text-align: center;\n      }\n      .calender__header--btn {\n        width: 3.2rem;\n        display: inline-block;\n        vertical-align: middle;\n        button {\n          padding: 0;\n          display: flex;\n          width: 3.2rem;\n          height: 3.2rem;\n          cursor: pointer;\n          border-radius: 50%;\n          align-items: center;\n          flex-direction: column;\n          justify-content: center;\n          &:hover {\n            background: var(--second-background-color);\n          }\n        }\n      }\n    }\n    .day {\n      width: calc(100% / 7);\n      height: 4rem;\n      display: inline-block;\n      vertical-align: top;\n      line-height: 4rem;\n      &.unavailable {\n        opacity: 0.4;\n      }\n      @media (max-width: 80em) {text-align: center;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
