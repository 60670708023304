import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quality-icons',
  templateUrl: './quality-icons.component.html',
  styleUrls: ['./quality-icons.component.scss']
})
export class QualityIconsComponent implements OnInit {

  @Input() public page: string = "profil"
  constructor() { }

  ngOnInit(): void {
  }

}
