import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { iShopProduct } from 'src/app/interfaces/shop.interface';
import { ShopService } from 'src/app/services/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  templateUrl: './product.page.html',
  styleUrls: ['./product.page.scss']
})
export class ProductPage implements OnInit {

  @Input() id: string = "";
  @Input() with_footer: boolean = true;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  public is_registered: boolean = false;
  public linkTo: string = "/faq#about_customer";
  public product: iShopProduct = <iShopProduct>{};

  constructor(
    private readonly _meta: Meta,
    private readonly _title: Title,
    private readonly _router: Router,
    private readonly _shopService: ShopService,
    private readonly _activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const id: string = this.id != "" ? this.id : this._activatedRoute.snapshot.params["product"];
    this.fetchProduct(id);
  }

  private fetchProduct(id: string): void {
    this._shopService.fetchShopItem(id).then((snapshot) => {
      if (snapshot.data != null) {
        this.handleResponse(snapshot.data);
      } else {
        this._router.navigateByUrl('404');
      }
    })
  }

  private handleResponse(data: any): void {
    this.product = data;
    if (this.with_footer) {
      this.setMetadata();
    }
  }


  public close(): void {
    this.visibleChange.emit(true);
  }

  public setMetadata(): void {
    this._title.setTitle(this.product.title);
    this._meta.updateTag({
      name: "description",
      content: `${this.product.title} de ${this.product.brand_name} à découvrir ou redécouvrir`,
    })
    this._meta.updateTag({
      property: "og:title",
      content: this.product.title,
    });
    this._meta.updateTag({
      property: "og:description",
      content: `${this.product.title} de ${this.product.brand_name} à découvrir ou redécouvrir`,
    })
    this._meta.updateTag({
      property: "og:url",
      content: `https://haizzy.fr/${this.product.id}`,
    })

    this._meta.updateTag({
      property: "og:image",
      content: this.product.image,
    })
  }


}
