// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  margin-bottom: 10rem;
}
.layout.related-articles {
  margin-bottom: 0;
}
.layout.related-articles .main-wrapper {
  margin: 0;
}
.layout .article__banner {
  height: 35rem;
}
.layout .date {
  font-size: 80%;
}
.layout .svg__drawing {
  margin: 5rem auto;
}
.layout .load {
  min-height: 100vh;
}
.layout .main-wrapper.related-articles {
  width: 100%;
}
.layout .main-wrapper.related-articles li {
  margin-bottom: 0;
  line-height: 1;
}
.layout .wrap-DIY .content {
  width: calc(100% - 320px);
  padding: 0 2rem;
}
.layout .wrap-DIY .content h2 {
  margin-bottom: 0;
}
.layout .wrap-DIY video {
  border-radius: var(--default-radius);
  overflow: hidden;
}
@media (min-width: 1023px) {
  .layout .wrap-DIY video {
    width: 320px;
  }
}
.layout .wrap-DIY > * {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1023px) {
  .layout .wrap-DIY > * {
    display: block;
    margin: 2rem 0;
    width: 100% !important;
  }
}

.bottom__banner {
  margin-bottom: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/article/article.page.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;AACI;EACI,gBAAA;AACR;AACQ;EACI,SAAA;AACZ;AAGI;EACI,aAAA;AADR;AAII;EACI,cAAA;AAFR;AAKI;EACI,iBAAA;AAHR;AAMI;EACI,iBAAA;AAJR;AAQI;EACI,WAAA;AANR;AAQQ;EACI,gBAAA;EACA,cAAA;AANZ;AAYQ;EACI,yBAAA;EACA,eAAA;AAVZ;AAYY;EACI,gBAAA;AAVhB;AAcQ;EACI,oCAAA;EACA,gBAAA;AAZZ;AAcY;EAJJ;IAKQ,YAAA;EAXd;AACF;AAcQ;EAEI,qBAAA;EACA,mBAAA;AAbZ;AAeY;EALJ;IAMQ,cAAA;IACA,cAAA;IACA,sBAAA;EAZd;AACF;;AAoBA;EACI,mBAAA;AAjBJ","sourcesContent":[".layout {\n    margin-bottom: 10rem;\n\n    &.related-articles {\n        margin-bottom: 0;\n\n        .main-wrapper {\n            margin: 0;\n        }\n    }\n\n    .article__banner {\n        height: 35rem;\n    }\n\n    .date {\n        font-size: 80%;\n    }\n\n    .svg__drawing {\n        margin: 5rem auto;\n    }\n\n    .load {\n        min-height: 100vh;\n    }\n\n\n    .main-wrapper.related-articles {\n        width: 100%;\n\n        li {\n            margin-bottom: 0;\n            line-height: 1;\n        }\n    }\n\n    .wrap-DIY {\n\n        .content {\n            width: calc(100% - 320px);\n            padding: 0 2rem;\n\n            h2 {\n                margin-bottom: 0;\n            }\n        }\n\n        video {\n            border-radius: var(--default-radius);\n            overflow: hidden;\n\n            @media (min-width: 1023px) {\n                width: 320px;\n            }\n        }\n\n        >* {\n\n            display: inline-block;\n            vertical-align: top;\n\n            @media (max-width: 1023px) {\n                display: block;\n                margin: 2rem 0;\n                width: 100% !important;\n            }\n\n\n        }\n    }\n}\n\n\n.bottom__banner {\n    margin-bottom: 6rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
